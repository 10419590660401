import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { motion, AnimatePresence, steps } from "framer-motion";
import HeaderNav from "./HeaderNav";
import Footer from "./Footer";
import Button from "../components/button";
import { useParams } from "react-router-dom";

import { Modal } from "antd";

import {
  ChevronDown,
  Server,
  Database,
  Cloud,
  Shield,
  Lightbulb,
  Search,
  Code,
  Laptop,
  Users,
  Target,
  Zap,
  Award,
  ThumbsUp,
  Briefcase,
  Box,
  Cpu,
  Settings,
  Cog,
  GitBranch,
  ArrowUpCircle,
  HeartPulse,
  PenTool,
  BarChart2,
  Shuffle,
  RefreshCw,
  FileCheck,
  Repeat,
  Workflow,
  Layers,
  Menu,
  X,
  Phone,
  GraduationCap,
  Network,
  Mail,
  MapPin,
  Linkedin,
  Twitter,
  Facebook,
  ArrowRight,
  ChevronRight,
  Wrench,
  ArrowRightLeft,
  BarChart,
  CheckCircle,
  Clipboard,
  ClipboardList,
  FileText,
  GitMerge,
  HeadphonesIcon,
  Rocket,
  ShieldCheck,
  Sliders,
  TestTube,
  Upload,
} from "lucide-react";
import { IdcardOutlined } from "@ant-design/icons";

const services = [
  {
    key: "Data",
    name: "Data Center & Operations",
    icon: Server,
    tagline: "Optimizing Your Infrastructure for Peak Performance",
    intro:
      "From virtualization to infrastructure automation, our Data Center & Operations service ensures your business runs on a robust, scalable, and efficient foundation. We help you consult, design, build, and maintain a data center environment that evolves with your business needs.",
    subServices: [
      {
        name: "Virtualization",
        icon: Box,
        description:
          "Empower your infrastructure with seamless virtualization solutions that increase efficiency, reduce costs, and boost flexibility. Our team consults on the right virtualization technologies for your needs, designs tailored setups, builds scalable systems, and provides comprehensive training for your team.",
      },
      {
        name: "Hyper-Converged Infrastructure (HCI)",
        icon: Database,
        description: `Simplify your data center with a hyper-converged solution that integrates storage, computing, and networking. We focus on modularity, scalability, and manageability, bringing you a robust HCI that's easy to operate and maintain.`,
      },
      {
        name: "Management",
        icon: Briefcase,
        description:
          "Streamline operations with advanced management tools and best practices. Our experts design and implement management strategies that simplify complex tasks, ensuring maximum uptime and efficiency.",
      },
      {
        name: "Operations",
        icon: Target,
        description:
          "Efficient operations are critical to business continuity. We establish processes and routines that optimize your data center operations, aligning with availability, security, and manageability principles.",
      },
      {
        name: "Infrastructure Automation",
        icon: Zap,
        description:
          "Reduce manual tasks with infrastructure automation, enabling faster deployments, improved accuracy, and better resource utilization. We leverage automation tools to create a self-sustaining infrastructure that grows with your needs.",
      },
    ],
  },
  {
    key: "Workload",
    name: "Workload Migrations",
    icon: Laptop,
    tagline: "Seamless Migrations for Business Continuity",
    intro:
      "Minimize downtime and streamline transitions with our end-to-end workload migration services. From P2V to X2X, we guide you through every step with a focus on availability, security, and reliability.",
    subServices: [
      {
        name: "Migration Strategy & Execution",
        icon: Target,
        description:
          "We start by understanding your unique requirements and design a migration strategy tailored to your environment. Whether on-premises, hybrid, or cloud, we ensure minimal disruption and maximum efficiency.",
      },
      {
        name: "P2V (Physical to Virtual)",
        icon: ArrowUpCircle,
        description:
          "Transform your physical infrastructure into a flexible virtual environment. Our team consults on the best virtualization platforms, designs migration workflows, builds the new environment, and trains your team to manage the virtual ecosystem.",
      },
      {
        name: "V2V (Virtual to Virtual)",
        icon: Shuffle,
        description:
          "Whether consolidating data centers or changing virtualization platforms, our V2V migration solutions make it easy. We handle the design and build-out with modular, manageable setups for an easy transition.",
      },
      {
        name: "X2V (Cross-Platform to Virtual)",
        icon: RefreshCw,
        description:
          "Transition from legacy platforms to modern virtual environments. We consult on best practices, build new configurations, and provide hands-on training for a smooth shift.",
      },
      {
        name: "X2C (Cross-Platform to Cloud)",
        icon: Cloud,
        description:
          "Accelerate cloud adoption with tailored migration strategies. Our team designs scalable, secure cloud environments that align with your business goals and future growth.",
      },
    ],
  },
  {
    key: "Business",
    name: "Business Continuity & Disaster Recovery",
    icon: Shield,
    tagline: "Building Resilience with Proven DR Solutions",
    intro:
      "Be prepared for any scenario with our Business Continuity & Disaster Recovery services. We design, build, and implement robust DR solutions that keep your business running smoothly, no matter what.",
    subServices: [
      {
        name: "Local & Remote Site Availability",
        icon: Server,
        description:
          "Ensure business continuity with site availability solutions that meet RPO, RTO, and SLA requirements. We build resilient systems that safeguard data and minimize downtime.",
      },
      {
        name: "RPO, RTO, SLA",
        icon: Target,
        description:
          "Our consultants help define realistic recovery objectives, ensuring that recovery plans align with your operational goals. We build systems with availability, security, and manageability in mind.",
      },
      {
        name: "Runbooks",
        icon: FileCheck,
        description:
          "Be ready with detailed, actionable runbooks that guide teams through every step of the DR process. We provide clear documentation and training to ensure your team is prepared.",
      },
      {
        name: "Failover & Failback",
        icon: Repeat,
        description:
          "Achieve seamless failover and failback to minimize disruption. Our team designs, builds, and tests failover systems to ensure they work when needed.",
      },
      {
        name: "DR Runbook & Automations",
        icon: Zap,
        description:
          "Automate DR processes to reduce response times and human error. We create runbooks that integrate with automated systems, ensuring rapid, reliable recovery.",
      },
    ],
  },
  {
    key: "Database",
    name: "Database Modernization",
    icon: Database,
    tagline: "Unlocking Data Potential with Modern Database Solutions",
    intro:
      "Modernize your databases for greater efficiency, scalability, and performance. Our team provides end-to-end database modernization services, from consulting to training, ensuring your data infrastructure is ready for the future.",
    subServices: [
      {
        name: "Database as a Service (DBaaS)",
        icon: Cloud,
        description:
          "Adopt a flexible, scalable DBaaS model that simplifies management and accelerates development. We handle the setup, maintenance, and optimization, leaving you to focus on your business.",
      },
      {
        name: "Database Migrations",
        icon: Shuffle,
        description:
          "Migrate databases to modern platforms with minimal risk. Our team consults on migration best practices, builds robust environments, and ensures data integrity throughout.",
      },
      {
        name: "Database Fit Checks",
        icon: FileCheck,
        description:
          "Ensure compatibility and performance with detailed fit checks. We evaluate your current setup, identify potential improvements, and design a solution that fits perfectly with your environment.",
      },
      {
        name: "Database Automations",
        icon: Zap,
        description:
          "Streamline database management with automation tools. We design workflows that reduce manual effort, improving efficiency and reducing errors.",
      },
    ],
  },
  {
    key: "Cloud",
    name: "Cloud Automations",
    icon: Cloud,
    tagline: "Driving Efficiency with Cloud Automation Solutions",
    intro:
      "Automate cloud processes to reduce costs, improve efficiency, and enable scalability. Our cloud automation services make managing complex environments simple, secure, and reliable.",
    subServices: [
      {
        name: "Private Cloud",
        icon: Shield,
        description:
          "Maximize control with private cloud automation solutions. Our experts build private cloud setups that are secure, manageable, and scalable to fit your needs.",
      },
      {
        name: "Hybrid Cloud",
        icon: Cloud,
        description:
          "Leverage the flexibility of a hybrid cloud with tailored automation solutions. We ensure seamless integration and smooth operation between on-prem and cloud platforms.",
      },
      {
        name: "3rd Party Integrations",
        icon: Workflow,
        description:
          "Integrate your cloud environment with third-party applications and platforms. We simplify workflows, building solutions that bring together all the tools your team relies on.",
      },
      {
        name: "Application Automations",
        icon: Zap,
        description:
          "Automate the deployment and management of applications to improve performance and speed up time-to-market.",
      },
      {
        name: "Process Automations",
        icon: Cog,
        description:
          "Optimize business processes with cloud-based automation, reducing manual steps and freeing up resources.",
      },
    ],
  },
  {
    key: "App",
    name: "App Modernization",
    icon: Code,
    tagline: "Empowering Innovation through Modern Applications",
    intro:
      "Revitalize legacy applications and make them cloud-ready with our app modernization services. We provide end-to-end support to help you adapt, modernize, and stay competitive.",
    subServices: [
      {
        name: "Containerization",
        icon: Box,
        description:
          "Containerize applications for greater portability and scalability. We help design, build, and implement containerized solutions that fit seamlessly with your operations.",
      },
      {
        name: "Kubernetes Platform",
        icon: Cpu,
        description:
          "Leverage Kubernetes for streamlined application deployment and management. We design Kubernetes-based solutions that make app management simple, scalable, and reliable.",
      },
      {
        name: "CI/CD Pipelines",
        icon: GitBranch,
        description:
          "Accelerate development with automated CI/CD pipelines. We create robust pipelines that integrate with your development process, bringing your products to market faster.",
      },
      {
        name: "Cloud-Native App Development",
        icon: Cloud,
        description:
          "Transform applications for the cloud. Our experts redesign apps to be more modular, manageable, and scalable.",
      },
    ],
  },
];

const subServ = [
  {
    id: "virtualization",
    icon: Box,
    name: "Virtualization",
    description:
      "Virtualization is the process of running multiple virtual machines (VMs) on a single physical hardware resource, enhancing resource efficiency and flexibility.",
    steps: [
      {
        icon: Cpu,
        title: "Consultation",
        description:
          "We begin by evaluating your current infrastructure, workload requirements, and business goals. This helps us choose the best virtualization technology (e.g., VMware, Hyper-V, KVM) based on performance, scalability, and cost-efficiency.",
      },
      {
        icon: Network,
        title: "Design",
        description:
          "We develop a comprehensive virtualization architecture that includes resource allocation, storage, network design, and security policies to ensure optimal VM performance and data protection.",
      },
      {
        icon: Box,
        title: "Implementation",
        description:
          "Our team sets up the virtualization environment, configuring hypervisors, virtual networks, storage systems, and creating templates for quick VM provisioning.",
      },
      {
        icon: GraduationCap,
        title: "Training & Support",
        description:
          "After deployment, we provide training for your IT team on managing virtual resources and offer ongoing support, performance monitoring, and optimization.",
      },
    ],
  },
  {
    id: "hci",
    name: "Hyper-Converged Infrastructure (HCI)",
    icon: Database,
    description:
      "Hyper-Converged Infrastructure (HCI) integrates compute, storage, and networking into a single system that reduces complexity and streamlines management. Our HCI implementation process includes:",
    steps: [
      {
        icon: BarChart2,
        title: "Assessment",
        description:
          "We analyze your current infrastructure to identify areas where HCI can simplify operations, increase resource efficiency, and reduce costs.",
      },
      {
        icon: PenTool,
        title: "Design",
        description:
          "Our team designs a modular and scalable HCI architecture tailored to your business needs, focusing on workload performance, fault tolerance, and future scalability.",
      },
      {
        icon: Server,
        title: "Deployment",
        description:
          "We deploy HCI components—such as software-defined storage, virtualized networks, and integrated compute—ensuring seamless interoperability and optimal performance.",
      },
      {
        icon: HeartPulse,
        title: "Ongoing Support",
        description:
          "We provide regular monitoring, updates, and troubleshooting to ensure the HCI environment consistently meets operational demands.",
      },
    ],
  },
  {
    id: "management",
    name: "Management",
    icon: Briefcase,
    description:
      "Effective IT management tools and strategies are essential for maintaining uptime and operational efficiency. We offer comprehensive management solutions that include:",
    steps: [
      {
        icon: Search,
        title: "Analysis",
        description:
          "We start by reviewing your current management tools, processes, and pain points, identifying areas where efficiency and visibility can be improved.",
      },
      {
        icon: Lightbulb,
        title: "Strategy Development",
        description:
          "We develop a tailored management strategy, implementing tools that support real-time monitoring, automated workflows, and proactive issue resolution.",
      },
      {
        icon: Cog,
        title: "Implementation",
        description:
          "Our team integrates the selected tools and configures dashboards, alerts, and reporting structures to minimize manual intervention and increase visibility.",
      },
      {
        icon: GraduationCap,
        title: "Training & Support",
        description:
          "We offer training to your team on using these tools effectively and provide ongoing support to adapt to any changes in your operational requirements.",
      },
    ],
  },
  {
    id: "operations",
    name: "Operations",
    icon: Target,
    description:
      "Efficient operations are crucial to maintaining business continuity and reducing operational costs. We enhance operational processes through:",
    steps: [
      {
        icon: ClipboardList,
        title: "Process Review",
        description:
          "We assess current workflows and procedures, identifying gaps in efficiency, security, and manageability.",
      },
      {
        icon: Zap,
        title: "Optimization",
        description:
          "We streamline processes, introducing automation where applicable to reduce overhead and enhance consistency.",
      },
      {
        icon: Rocket,
        title: "Implementation",
        description:
          "Optimized workflows and tools are deployed, ensuring seamless integration with your existing environment.",
      },
      {
        icon: RefreshCw,
        title: "Continuous Improvement",
        description:
          "We conduct periodic reviews to ensure that operational processes evolve with changing business needs and technological advancements.",
      },
    ],
  },
  {
    id: "infrastructure_automation",
    name: "Infrastructure Automation",
    icon: Zap,
    description:
      "Infrastructure automation reduces manual tasks, accelerates deployment times, and optimizes resource allocation. Our approach includes:",
    steps: [
      {
        icon: Clipboard,
        title: "Assessment",
        description:
          "We evaluate repetitive tasks and processes to identify areas for automation.",
      },
      {
        icon: Wrench,
        title: "Tool Selection",
        description:
          "We recommend and configure automation tools (e.g., Ansible, Terraform) that suit your specific infrastructure and operational requirements.",
      },
      {
        icon: GitBranch,
        title: "Workflow Automation",
        description:
          "Custom workflows are designed and automated, handling tasks such as provisioning, scaling, and updating infrastructure.",
      },
      {
        icon: BarChart,
        title: "Monitoring and Support",
        description:
          "We provide monitoring for automated tasks, ensuring accuracy and reliability. Regular adjustments are made to enhance efficiency further.",
      },
    ],
  },
  {
    IdcardOutlined: "migration_strategy_execution",
    name: "Migration Strategy & Execution",
    icon: Target,
    description:
      "Seamless migrations require careful planning and execution to ensure business continuity. Our approach includes:",
    steps: [
      {
        icon: Search,
        title: "Assessment",
        description:
          "We analyze your current environment, workloads, and dependencies to design a custom migration plan.",
      },
      {
        icon: Lightbulb,
        title: "Strategy Development",
        description:
          "Develop a migration strategy aligned with your goals—whether it's on-premises, hybrid, or cloud-based—to ensure minimal downtime.",
      },
      {
        icon: Cog,
        title: "Implementation",
        description:
          "Execute migrations with robust tools and methodologies to maintain availability, security, and data integrity.",
      },
      {
        icon: Wrench,
        title: "Support & Optimization",
        description:
          "Offer post-migration support, fine-tuning configurations, and ensuring a stable environment.",
      },
    ],
  },
  {
    key: "p2v",
    name: "P2V (Physical to Virtual)",
    icon: ArrowUpCircle,
    description:
      "Transform your physical infrastructure into a flexible virtual environment with ease.",
    steps: [
      {
        icon: Users,
        title: "Consultation",
        description:
          "Assess the existing physical infrastructure and recommend suitable virtualization platforms.",
      },
      {
        icon: PenTool,
        title: "Design",
        description:
          "Develop a detailed migration workflow covering resource allocation, storage mapping, and network configurations.",
      },
      {
        icon: Server,
        title: "Implementation",
        description:
          "Migrate physical servers to a virtualized environment using industry-best practices to minimize disruption.",
      },
      {
        icon: GraduationCap,
        title: "Training",
        description:
          "Train your IT team to efficiently manage and maintain the virtual ecosystem.",
      },
    ],
  },
  {
    id: "v2v",
    name: "V2V (Virtual to Virtual)",
    icon: Shuffle,
    description:
      "Simplify transitions between virtualization platforms or data center consolidations.",
    steps: [
      {
        icon: Search,
        title: "Assessment",
        description:
          "Review current virtualized environments to identify dependencies and challenges.",
      },
      {
        icon: PenTool,
        title: "Design",
        description:
          "Create modular migration workflows ensuring scalability and compatibility with the target platform.",
      },
      {
        icon: ArrowRightLeft,
        title: "Execution",
        description:
          "Perform migrations seamlessly with minimal impact on running workloads.",
      },
      {
        icon: Wrench,
        title: "Post-Migration Support",
        description:
          "Fine-tune the new environment for optimal performance and usability.",
      },
    ],
  },
  {
    id: "x2v",
    name: "X2V (Cross-Platform to Virtual)",
    icon: RefreshCw,
    description:
      "Upgrade from legacy platforms to modern virtual environments with confidence.",
    steps: [
      {
        icon: Users,
        title: "Consultation",
        description:
          "Assess legacy systems and recommend modern virtualization solutions.",
      },
      {
        icon: PenTool,
        title: "Design",
        description:
          "Build new configurations ensuring compatibility with existing workloads.",
      },
      {
        icon: ArrowRightLeft,
        title: "Migration",
        description:
          "Transition workloads to the new virtual environment using a step-by-step process.",
      },
      {
        icon: GraduationCap,
        title: "Training",
        description:
          "Empower your team with the skills needed to operate and manage the upgraded environment.",
      },
    ],
  },
  {
    id: "x2c",
    name: "X2C (Cross-Platform to Cloud)",
    icon: Cloud,
    description:
      "Adopt cloud technology for enhanced scalability and cost-efficiency.",
    steps: [
      {
        icon: Search,
        title: "Assessment",
        description: "Evaluate current workloads and identify cloud readiness.",
      },
      {
        icon: Lightbulb,
        title: "Strategy",
        description:
          "Design a migration strategy aligned with your business goals and future growth.",
      },
      {
        icon: Upload,
        title: "Cloud Deployment",
        description:
          "Build secure, scalable cloud environments using best-in-class tools and practices.",
      },
      {
        icon: BarChart,
        title: "Support",
        description:
          "Provide post-migration optimization and monitoring to ensure seamless cloud adoption.",
      },
    ],
  },
  {
    id: "availability",
    name: "Local & Remote Site Availability",
    icon: Server,
    description:
      "Ensure uninterrupted business operations with solutions designed for both local and remote site availability.",
    steps: [
      {
        icon: Search,
        title: "Assessment",
        description:
          "Evaluate your current infrastructure to identify critical workloads and dependencies.",
      },
      {
        icon: PenTool,
        title: "Design",
        description:
          "Develop solutions that meet RPO (Recovery Point Objective), RTO (Recovery Time Objective), and SLA (Service Level Agreement) requirements.",
      },
      {
        icon: Server,
        title: "Implementation",
        description:
          "Deploy robust systems ensuring data availability and minimal downtime across local and remote sites.",
      },
      {
        icon: BarChart,
        title: "Monitoring & Optimization",
        description:
          "Provide ongoing support to optimize performance and ensure readiness for any scenario.",
      },
    ],
  },
  {
    id: "rpo_rto_sla",
    name: "RPO, RTO, SLA",
    icon: Target,
    description:
      "Define and achieve recovery objectives tailored to your business goals.",
    steps: [
      {
        icon: Users,
        title: "Consultation",
        description:
          "Work with stakeholders to define realistic recovery objectives (RPO, RTO) and align them with business operations.",
      },
      {
        icon: PenTool,
        title: "Design",
        description:
          "Architect systems with high availability, security, and manageability to meet these objectives.",
      },
      {
        icon: ShieldCheck,
        title: "Validation",
        description:
          "Test and validate recovery plans to ensure they align with operational goals.",
      },
      {
        icon: RefreshCw,
        title: "Ongoing Review",
        description:
          "Continuously refine recovery strategies to adapt to changing business needs.",
      },
    ],
  },
  {
    id: "runbooks",
    name: "Runbooks",
    icon: FileCheck,
    description:
      "Be prepared with comprehensive, actionable plans for disaster scenarios.",
    steps: [
      {
        icon: Search,
        title: "Analysis",
        description:
          "Identify key processes and scenarios requiring detailed documentation.",
      },
      {
        icon: FileText,
        title: "Documentation",
        description:
          "Develop step-by-step runbooks that clearly outline actions for recovery.",
      },
      {
        icon: GraduationCap,
        title: "Training",
        description:
          "Provide hands-on training to ensure teams are familiar with runbook procedures.",
      },
      {
        icon: RefreshCw,
        title: "Review & Update",
        description:
          "Periodically update runbooks to reflect changes in the environment or business priorities.",
      },
    ],
  },
  {
    id: "failover_failback",
    name: "Failover & Failback",
    icon: Repeat,
    description:
      "Seamless transitions during disruptions to ensure operational continuity.",
    steps: [
      {
        icon: PenTool,
        title: "Design",
        description:
          "Architect failover and failback systems that ensure seamless transitions without disrupting operations.",
      },
      {
        icon: TestTube,
        title: "Testing",
        description:
          "Conduct rigorous testing to validate the failover and failback process under real-world conditions.",
      },
      {
        icon: Server,
        title: "Deployment",
        description:
          "Implement systems with redundancy and high availability to support business-critical applications.",
      },
      {
        icon: BarChart,
        title: "Support",
        description:
          "Provide ongoing monitoring and support to ensure reliability during actual failover events.",
      },
    ],
  },
  {
    id: "dr_runbook_automations",
    name: "DR Runbook & Automations",
    icon: Zap,
    description:
      "Automate disaster recovery processes for faster, more reliable responses.",
    steps: [
      {
        icon: Search,
        title: "Assessment",
        description:
          "Identify repetitive tasks and steps within the DR process that can be automated.",
      },
      {
        icon: Cog,
        title: "Automation Design",
        description:
          "Develop and integrate automated workflows for rapid failover, failback, and recovery processes.",
      },
      {
        icon: GitMerge,
        title: "Integration",
        description:
          "Seamlessly integrate automation tools with runbooks for coordinated recovery efforts.",
      },
      {
        icon: ShieldCheck,
        title: "Validation",
        description:
          "Test automated systems to ensure reliability and performance during disaster events.",
      },
    ],
  },
  {
    id: "database_migrations",
    name: "Database Migrations",
    icon: Cloud,
    description:
      "Transition to modern database platforms with minimal risk and maximum efficiency.",
    steps: [
      {
        icon: Search,
        title: "Assessment",
        description:
          "Evaluate your current database systems to identify the best migration strategy.",
      },
      {
        icon: ClipboardList,
        title: "Planning",
        description:
          "Design a detailed migration workflow to ensure data integrity, minimize downtime, and address dependencies.",
      },
      {
        icon: ArrowRightLeft,
        title: "Execution",
        description:
          "Perform the migration using proven tools and methodologies to ensure seamless transitions.",
      },
      {
        icon: Wrench,
        title: "Post-Migration Support",
        description:
          "Provide optimization, validation, and troubleshooting for the new environment.",
      },
    ],
  },
  {
    id: "dbaas",
    name: "Database as a Service (DBaaS)",
    icon: Shuffle,
    description:
      "Adopt a flexible, scalable DBaaS model to simplify database management and accelerate development.",
    steps: [
      {
        icon: Users,
        title: "Consultation",
        description:
          "Assess your current database infrastructure and identify areas where a DBaaS model can improve scalability and efficiency.",
      },
      {
        icon: Server,
        title: "Setup",
        description:
          "Deploy DBaaS solutions tailored to your operational needs, ensuring seamless integration with existing systems.",
      },
      {
        icon: Sliders,
        title: "Optimization",
        description:
          "Continuously monitor and fine-tune DBaaS environments to maximize performance and cost-efficiency.",
      },
      {
        icon: HeadphonesIcon,
        title: "Support",
        description:
          "Provide ongoing management and support to ensure reliability and scalability as your business grows.",
      },
    ],
  },
  {
    id: "database_fit_checks",
    name: "Database Fit Checks",
    icon: FileCheck,
    description:
      "Ensure compatibility and performance with comprehensive database fit checks.",
    steps: [
      {
        icon: BarChart2,
        title: "Analysis",
        description:
          "Review your current database systems and workloads to identify performance bottlenecks and inefficiencies.",
      },
      {
        icon: CheckCircle,
        title: "Evaluation",
        description:
          "Conduct compatibility assessments to ensure the selected platform meets your business requirements.",
      },
      {
        icon: PenTool,
        title: "Design",
        description:
          "Develop tailored database solutions that align with your environment and future growth.",
      },
      {
        icon: Zap,
        title: "Validation",
        description:
          "Test the new database configuration to ensure it meets performance and reliability standards.",
      },
    ],
  },
  {
    id: "database_automations",
    name: "Database Automations",
    icon: Zap,
    description:
      "Enhance efficiency with automated database management workflows.",
    steps: [
      {
        icon: Search,
        title: "Assessment",
        description:
          "Identify repetitive tasks and processes that can be automated to reduce manual effort.",
      },
      {
        icon: Wrench,
        title: "Tool Selection",
        description:
          "Recommend and configure automation tools that suit your database management needs.",
      },
      {
        icon: GitBranch,
        title: "Workflow Design",
        description:
          "Develop custom workflows to automate tasks such as backups, scaling, monitoring, and updates.",
      },
      {
        icon: BarChart,
        title: "Monitoring & Optimization",
        description:
          "Provide ongoing support to monitor and refine automated processes for peak efficiency.",
      },
    ],
  },
  {
    id: "hybrid_cloud",
    name: "Hybrid Cloud",
    icon: Shield,
    description:
      "Leverage the best of both on-premises and cloud environments with hybrid cloud automation.",
    steps: [
      {
        icon: Search,
        title: "Analysis",
        description:
          "Assess your hybrid cloud environment to identify integration challenges and automation opportunities.",
      },
      {
        icon: PenTool,
        title: "Design",
        description:
          "Develop tailored automation workflows for seamless operation between on-prem and cloud platforms.",
      },
      {
        icon: Rocket,
        title: "Deployment",
        description:
          "Implement automation tools that enable dynamic resource allocation and simplified management.",
      },
      {
        icon: BarChart,
        title: "Monitoring & Support",
        description:
          "Ensure ongoing performance and reliability through regular monitoring and support.",
      },
    ],
  },
  {
    id: "private_cloud",
    name: "Private Cloud",
    icon: Cloud,
    description:
      "Maximize control and efficiency with private cloud automation solutions.",
    steps: [
      {
        icon: Search,
        title: "Assessment",
        description:
          "Analyze your current private cloud setup or requirements to identify automation opportunities.",
      },
      {
        icon: PenTool,
        title: "Design",
        description:
          "Develop secure, scalable, and manageable private cloud architectures tailored to your business needs.",
      },
      {
        icon: Cog,
        title: "Automation Implementation",
        description:
          "Automate tasks like resource provisioning, scaling, and monitoring for seamless operations.",
      },
      {
        icon: BarChart,
        title: "Support & Optimization",
        description:
          "Provide ongoing support and performance optimization to ensure your private cloud meets evolving business goals.",
      },
    ],
  },
  {
    id: "third_party_integrations",
    name: "3rd Party Integrations",
    icon: Workflow,
    description:
      "Seamlessly integrate your cloud environment with third-party tools and platforms.",
    steps: [
      {
        icon: Search,
        title: "Evaluation",
        description:
          "Assess your existing tools and workflows to identify integration points.",
      },
      {
        icon: PenTool,
        title: "Design",
        description:
          "Build integration solutions that unify third-party applications with your cloud environment.",
      },
      {
        icon: GitMerge,
        title: "Implementation",
        description:
          "Configure and automate workflows to simplify processes and enhance collaboration.",
      },
      {
        icon: Wrench,
        title: "Maintenance",
        description:
          "Provide continuous support to ensure integrations remain operational and adapt to new tools as needed.",
      },
    ],
  },
  {
    id: "application_automations",
    name: "Application Automations",
    icon: Zap,
    description:
      "Streamline application deployment and management to boost performance and reduce time-to-market.",
    steps: [
      {
        icon: ClipboardList,
        title: "Planning",
        description:
          "Identify applications that benefit from automation in deployment, scaling, and updates.",
      },
      {
        icon: Cog,
        title: "Automation Design",
        description:
          "Build workflows for automated application lifecycle management, including deployment, monitoring, and scaling.",
      },
      {
        icon: Rocket,
        title: "Deployment",
        description:
          "Implement automated solutions to reduce manual intervention and improve efficiency.",
      },
      {
        icon: GraduationCap,
        title: "Ongoing Support",
        description:
          "Provide training and support to ensure your team can manage and optimize automated applications.",
      },
    ],
  },
  {
    id: "process_automations",
    name: "Process Automations",
    icon: Cog,
    description:
      "Optimize business processes with cloud-based automation solutions.",
    steps: [
      {
        icon: Search,
        title: "Process Review",
        description:
          "Identify repetitive manual tasks and inefficiencies in your business processes.",
      },
      {
        icon: GitBranch,
        title: "Workflow Design",
        description:
          "Develop and implement automated workflows to streamline operations and reduce costs.",
      },
      {
        icon: Rocket,
        title: "Implementation",
        description:
          "Deploy cloud-based automation tools tailored to your process requirements.",
      },
      {
        icon: RefreshCw,
        title: "Continuous Improvement",
        description:
          "Monitor, refine, and enhance automated workflows to meet evolving business needs.",
      },
    ],
  },
  {
    id: "containerization",
    name: "Containerization",
    icon: Box,
    description:
      "Transform your applications with containerized solutions for enhanced portability and scalability.",
    steps: [
      {
        icon: Search,
        title: "Assessment",
        description:
          "Evaluate current applications to identify containerization opportunities.",
      },
      {
        icon: PenTool,
        title: "Design",
        description:
          "Develop a containerization strategy aligned with your operational and scalability needs.",
      },
      {
        icon: Rocket,
        title: "Implementation",
        description:
          "Build and deploy containerized applications, integrating them seamlessly with your existing environment.",
      },
      {
        icon: BarChart,
        title: "Support",
        description:
          "Provide ongoing monitoring and optimization to ensure containerized applications deliver peak performance.",
      },
    ],
  },
  {
    id: "kubernetes_platform",
    name: "Kubernetes Platform",
    icon: Cpu,
    description:
      "Simplify and streamline application management with Kubernetes.",
    steps: [
      {
        icon: ClipboardList,
        title: "Planning",
        description:
          "Analyze application requirements and workflows to design a Kubernetes-based solution.",
      },
      {
        icon: Rocket,
        title: "Deployment",
        description:
          "Set up Kubernetes clusters, enabling efficient application deployment, scaling, and monitoring.",
      },
      {
        icon: GitMerge,
        title: "Integration",
        description:
          "Integrate Kubernetes with CI/CD pipelines and other development tools for streamlined processes.",
      },
      {
        icon: GraduationCap,
        title: "Support",
        description:
          "Offer training and ongoing support to ensure efficient Kubernetes cluster management.",
      },
    ],
  },
  {
    id: "ci_cd_pipelines",
    name: "CI/CD Pipelines",
    icon: GitBranch,
    description:
      "Accelerate development and deployment with automated CI/CD pipelines.",
    steps: [
      {
        icon: Search,
        title: "Analysis",
        description:
          "Evaluate your development lifecycle to identify bottlenecks and automation opportunities.",
      },
      {
        icon: PenTool,
        title: "Design",
        description:
          "Build robust CI/CD pipelines tailored to your workflows and tools.",
      },
      {
        icon: Rocket,
        title: "Implementation",
        description:
          "Integrate automated pipelines into your development process for faster, more reliable releases.",
      },
      {
        icon: BarChart,
        title: "Optimization",
        description:
          "Continuously monitor and refine pipelines to ensure efficiency and adaptability to changing needs.",
      },
    ],
  },
  {
    id: "cloud_native_app_development",
    name: "Cloud-Native App Development",
    icon: Cloud,
    description: "Revitalize legacy applications for the cloud era.",
    steps: [
      {
        icon: Search,
        title: "Assessment",
        description:
          "Analyze legacy applications to identify modernization opportunities and gaps.",
      },
      {
        icon: PenTool,
        title: "Design",
        description:
          "Develop a cloud-native architecture focused on modularity, scalability, and manageability.",
      },
      {
        icon: Code,
        title: "Development",
        description:
          "Transform applications into cloud-native solutions using microservices, APIs, and modern frameworks.",
      },
      {
        icon: GraduationCap,
        title: "Training & Support",
        description:
          "Provide training for managing cloud-native apps and ongoing support for optimal performance.",
      },
    ],
  },
];

export default function ServicesPage() {
  const [modalData, setModalData] = useState({});
  const { ServiceNames } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeService, setActiveService] = useState(
    services.find((item) => item?.key === ServiceNames) || services[0]
  );
  const [hoveredService, setHoveredService] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const servicesRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  useEffect(() => {
    !ServiceNames && window.scrollTo(0, 0);
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  const handleServiceClick = (service) => {
    setActiveService(service);

    if (servicesRef.current && ServiceNames) {
      // Scroll to the section
      servicesRef.current.scrollIntoView({ behavior: "smooth" });

      if (window.innerWidth < 768) {
        setTimeout(() => {
          window.scrollBy({ top: 400, left: 0, behavior: "smooth" });
        }, 500);
      }
    }
  };

  useEffect(() => {
    const value = services.find((item) => item?.key === ServiceNames);

    const defaultValue = value || services[0];

    handleServiceClick(defaultValue);
  }, [ServiceNames]);

  // const handleCustomServiceClick = () => {
  //   navigate("/build-custom-service");
  // };

  const getSubServicesdata = (subService) => {
    let modalData = {};
    for (let i = 0; i < subServ.length; i++) {
      if (subServ[i].name === subService) {
        modalData = subServ[i];
      }
    }
    return modalData;
  };
  const handleSubServiceClick = (subService) => {
    const data = getSubServicesdata(subService);
    if (data.length === 0) {
      alert("aubServices Not available");
    }
    setModalData(data);
    openModal();
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <HeaderNav />
      {/* Main Content */}
      <main className="pt-20">
        <section className="bg-gradient-to-r from-blue-600 to-purple-600 text-white py-20">
          <div className="container mx-auto px-4">
            <h1 className="text-4xl font-bold mb-6 text-center">
              Our Services
            </h1>
            <p className="text-xl mb-12 text-center max-w-3xl mx-auto">
              Discover how our comprehensive range of services can transform
              your business and drive innovation.
            </p>
          </div>
        </section>

        <section ref={servicesRef} className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-1/4 mb-8 lg:mb-0">
                <div className=" top-24 overflow-y-auto max-h-[calc(100vh-6rem)]">
                  <h2 className="text-2xl font-bold mb-4">Our Services</h2>
                  {services.map((service) => (
                    <motion.button
                      key={service.name}
                      onClick={() => handleServiceClick(service)}
                      className={`flex items-center w-full text-left p-3 rounded-lg mb-2 transition-colors ${
                        activeService === service
                          ? "bg-blue-100 text-blue-700"
                          : "hover:bg-gray-100"
                      }`}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <service.icon className="h-6 w-6 mr-3" />
                      <span className="font-medium">{service.name}</span>
                    </motion.button>
                  ))}
                </div>
              </div>
              <div className="lg:w-3/4 lg:pl-8">
                <AnimatePresence mode="wait">
                  {activeService && (
                    <motion.div
                      key={activeService.name}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="flex justify-between shadow-md p-3 rounded-lg items-center mb-6  top-20 bg-white z-10 py-4">
                        <h2 className="text-3xl font-bold flex items-center">
                          <activeService.icon className="h-8 w-8 mr-3 text-blue-600" />
                          {activeService.name}
                        </h2>
                        {/* <Button
                          onClick={handleCustomServiceClick}
                          className="bg-blue-600 hover:bg-blue-700 text-white flex items-center space-x-2"
                        >
                          <Wrench className="h-5 w-5" />
                          <span>Build My Custom Service</span>
                        </Button> */}
                      </div>
                      <p className="text-xl text-gray-600 mb-6">
                        {activeService.tagline}
                      </p>
                      <p className="text-gray-700 mb-8">
                        {activeService.intro}
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                        {activeService.subServices.map((subService) => (
                          <motion.div
                            key={subService.name}
                            className="bg-white p-6 rounded-lg shadow-md cursor-pointer transition-colors hover:shadow-lg"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() =>
                              handleSubServiceClick(subService.name)
                            }
                          >
                            <subService.icon className="h-8 w-8 text-blue-600 mb-4" />
                            <h3 className="text-xl font-semibold mb-2">
                              {subService.name}
                            </h3>
                            <p className="text-gray-600 text-sm">
                              {subService.description}
                            </p>
                            <div className="flex items-center mt-4 text-blue-600">
                              <span
                                className="mr-2"
                                // onClick={() => setIsModalOpen(!isModalOpen)}
                                onClick={() =>
                                  handleSubServiceClick(subService.name)
                                }
                              >
                                Learn More
                              </span>
                              <ChevronRight className="h-5 w-5" />
                            </div>
                          </motion.div>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </section>

        <section className="py-20 bg-blue-600 text-white">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-6">
              Ready to Transform Your Business?
            </h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto">
              Let's discuss how our services can help you achieve your goals and
              drive innovation in your organization.
            </p>
            <div className="flex justify-center items-center">
              <Link to="/ContactUs">
                <button className="bg-white flex items-center py-2 px-4 rounded-xl text-blue-600 hover:bg-gray-100">
                  <div>Contact Us</div>
                  <ArrowRight className="ml-2 h-4 w-6" />
                </button>
              </Link>
            </div>
          </div>
        </section>
      </main>
      {isModalOpen && modalData && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex mt-16 justify-center p-4 scrollbar-hide">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="w-full max-w-2xl max-h-[90vh] bg-gradient-to-br from-[#4527A0] via-[#673AB7] to-[#4527A0] rounded-2xl shadow-2xl overflow-hidden"
          >
            {/* Content */}
            <div className="relative scrollbar-hide">
              {/* Header */}
              <div className="flex items-center justify-between p-6 border-b border-white/10">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-white/10 rounded-lg">
                    <modalData.icon className="w-6 h-6 text-white" />
                  </div>
                  <h2 className="text-2xl font-semibold text-white">
                    {modalData.name}
                  </h2>
                </div>
                <button
                  className="p-2 hover:bg-white/10 rounded-full transition-colors"
                  onClick={closeModal}
                >
                  <X className="w-5 h-5 text-white/70 hover:text-white" />
                </button>
              </div>

              {/* Body - Made scrollable */}
              <div className="overflow-y-auto max-h-[calc(90vh-8rem)]">
                <div className="p-6 space-y-6">
                  <p className="text-white/90 leading-relaxed text-lg">
                    {modalData.description}
                  </p>

                  <div className="space-y-6">
                    <h3 className="text-xl font-semibold text-white">
                      Our {modalData.title} process involves:
                    </h3>

                    {modalData.steps.map((step, index) => (
                      <motion.div
                        key={step.name}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.1 }}
                        className="bg-white/5 rounded-xl p-6 hover:bg-white/10 transition-colors"
                      >
                        <div className="flex gap-4">
                          <div className="bg-gradient-to-br from-purple-400/30 to-purple-400/10 p-3 rounded-lg">
                            <step.icon className="w-6 h-6 text-white" />
                          </div>
                          <div className="flex-1">
                            <h3 className="text-xl font-semibold mb-3 text-white">
                              {step.title}
                            </h3>
                            <p className="text-white/80 leading-relaxed">
                              {step.description}
                            </p>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Footer */}
              <div className="p-6 border-t border-white/10 bg-black/20">
                <button className="w-full bg-white/10 hover:bg-white/20 text-white font-semibold py-3 px-6 rounded-lg transition-all duration-300">
                  Learn More About {activeService.title}
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
}
