import React, { useEffect, useRef } from "react";
import HeaderNav from "../HeaderNav";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import {
  motion,
  useAnimation,
  useInView,
  useScroll,
  useTransform,
} from "framer-motion";
import {
  Clock,
  DollarSign,
  Shield,
  Cloud,
  Server,
  CheckCircle,
  ChevronRight,
  Network,
  Database,
  Lock,
} from "lucide-react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import soudi_digital_leader from "../../assets/SuccessStories/soudi_digital_leader.jpg";

const ParallaxSection = ({ children, className = "" }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const y = useTransform(scrollYProgress, [0, 1], [50, -50]);

  return (
    <motion.div ref={ref} style={{ y }} className={className}>
      {children}
    </motion.div>
  );
};

const AnimatedCounter = ({ target, duration = 2 }) => {
  const nodeRef = useRef(null);
  const controls = useAnimation();
  const inView = useInView(nodeRef);

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.5 },
      });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={nodeRef}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={controls}
    >
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: duration, ease: "easeOut" }}
      >
        {target}
      </motion.span>
      <span>{target !== 20 ? "%" : "x"}</span>
    </motion.div>
  );
};

const GlowingCard = ({ children, className = "" }) => (
  <motion.div
    className={`relative group ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ duration: 0.3 }}
  >
    <motion.div
      className="absolute inset-0 bg-gradient-to-r from-cyan-500/20 to-blue-500/20 rounded-lg blur-xl group-hover:opacity-75 transition-opacity duration-500 opacity-0"
      animate={{
        scale: [1, 1.1, 1],
        opacity: [0.5, 0.7, 0.5],
      }}
      transition={{
        duration: 4,
        repeat: Infinity,
        ease: "linear",
      }}
    />
    {children}
  </motion.div>
);

const SaudiDigitalLeaderSuccessStory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderNav />
      <Box sx={{ minHeight: "100vh", bgcolor: "background.paper" }}>
        {/* Hero Section */}
        <div className="relative h-[500px] md:min-h-screen flex items-end justify-center overflow-hidden">
          {/* Background Image */}
          <div className="absolute inset-0">
            <img
              src={soudi_digital_leader}
              alt="Digital Transformation in Saudi Arabia"
              className="w-full h-full object-cover"
            />
          </div>
          <motion.div
            className="absolute inset-0 z-0"
            animate={{
              backgroundColor: [
                "rgba(0,0,0,0.3)",
                "rgba(0,0,0,0.5)",
                "rgba(0,0,0,0.3)",
              ],
            }}
            transition={{
              duration: 5,
              repeat: Infinity,
              ease: "linear",
            }}
          />

          {/* Content */}
          <div className="relative z-10 max-w-4xl px-4 mb-5 text-center flex flex-col p-4 rounded-lg bg-black bg-opacity-50 items-end justify-center">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
              Transforming Saudi's Largest Digital Leader with End-to-End
              Automation Solutions
            </h1>
            <p className="text-lg md:text-xl text-white">
              A journey to operational excellence, scalability, and robust
              governance through automation
            </p>
          </div>
        </div>

        {/* Background and Challenge Section */}
        <motion.section
          className="py-20 bg-gray-50"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <div className="container mx-auto px-4">
            <ParallaxSection className="mb-12">
              <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-900">
                Background and Challenge
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-2 gap-8">
              <GlowingCard className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
                  Background
                </h3>
                <p className="text-base md:text-lg text-gray-600">
                  Saudi Arabia's leading digital solutions provider sought to
                  elevate their infrastructure capabilities. They turned to us
                  for an automation-driven, scalable solution to integrate and
                  automate their IaaS and PaaS environments, while ensuring
                  compliance, seamless network integration, and dynamic resource
                  management.
                </p>
              </GlowingCard>
              <GlowingCard className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
                  Challenges
                </h3>
                <div>
                  {[
                    {
                      icon: Cloud,
                      text: "Automating IaaS and PaaS environments with Nutanix Database Service integration",
                    },
                    {
                      icon: Lock,
                      text: "Enforcing strict compliance and hardening policies through Nutanix Calm",
                    },
                    {
                      icon: Network,
                      text: "Establishing dynamic network management tied to user roles and project associations",
                    },
                    {
                      icon: Server,
                      text: "Achieving end-to-end automation of network infrastructure",
                    },
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <item.icon className="mr-3 text-blue-500 text-xl" />
                      <p className="text-gray-600"> {item.text}</p>
                    </li>
                  ))}
                </div>
              </GlowingCard>
            </div>
          </div>
        </motion.section>

        {/* Solution Section */}
        <motion.section
          className="py-20 bg-black text-white relative overflow-hidden"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <motion.div
            className="absolute inset-0 opacity-20"
            animate={{
              background: [
                "radial-gradient(circle at 30% 30%, #22D3EE 0%, transparent 60%)",
                "radial-gradient(circle at 70% 70%, #22D3EE 0%, transparent 60%)",
                "radial-gradient(circle at 30% 30%, #22D3EE 0%, transparent 60%)",
              ],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <div className="container mx-auto px-4 relative z-10">
            <ParallaxSection>
              <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
                Our Solution
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  title: "NCM Self-Service Workflows",
                  description:
                    "Implemented for provisioning and managing IaaS and PaaS environments, integrated with Nutanix Database Service.",
                  icon: Cloud,
                },
                {
                  title: "Dynamic Network Configuration",
                  description:
                    "Designed system based on user role and project association for real-time resource allocation.",
                  icon: Network,
                },
                {
                  title: "Comprehensive Automation",
                  description:
                    "Developed workflows for seamless integration of Cisco Switches, ACI, Infoblox, Panorama, and Nutanix AHV Networking.",
                  icon: Server,
                },
                {
                  title: "Advanced Error Handling",
                  description:
                    "Introduced mechanisms and real-time notifications to ensure operational visibility and reliability.",
                  icon: CheckCircle,
                },
                {
                  title: "Compliance Framework",
                  description:
                    "Delivered automation framework enforcing compliance and hardening standards across the infrastructure.",
                  icon: Shield,
                },
              ].map((item, index) => (
                <GlowingCard
                  key={index}
                  className="bg-white/10 backdrop-blur-sm p-6 rounded-lg"
                >
                  <item.icon className="w-12 h-12 mb-4 text-cyan-400" />
                  <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                  <p className="text-gray-300">{item.description}</p>
                </GlowingCard>
              ))}
            </div>
          </div>
        </motion.section>

        {/* Impact Section */}
        <Box sx={{ py: 10, bgcolor: "background.paper" }}>
          <Container maxWidth="lg">
            <ParallaxSection>
              <Typography variant="h3" align="center" gutterBottom>
                The Impact
              </Typography>
            </ParallaxSection>
            <Grid container spacing={4} sx={{ mb: 6 }}>
              {[
                { value: 80, label: "Faster Network Deployments", icon: Clock },
                {
                  value: 90,
                  label: "Compliance Across IaaS and PaaS",
                  icon: Shield,
                },
                {
                  value: 70,
                  label: "Automation of Network Management",
                  icon: Network,
                },
                { value: 20, label: "Faster SQL Deployments", icon: Database },
              ].map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <GlowingCard className="h-[250px] text-center bg-gray-50 p-6 rounded-lg shadow-lg">
                    <CardContent sx={{ textAlign: "center" }}>
                      <item.icon className="w-12 h-12 mx-auto mb-4 text-cyan-600" />
                      <Typography variant="h4" component="div" gutterBottom>
                        <AnimatedCounter target={item.value} />
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.label}
                      </Typography>
                    </CardContent>
                  </GlowingCard>
                </Grid>
              ))}
            </Grid>
            <Typography
              variant="body1"
              align="center"
              sx={{ maxWidth: "800px", mx: "auto" }}
            >
              Our solution dramatically improved network provisioning speed,
              ensured seamless compliance management, and achieved end-to-end
              automation across the entire infrastructure, positioning the
              organization for sustained growth and innovation in the dynamic
              digital landscape.
            </Typography>
          </Container>
        </Box>

        {/* Testimonial Section */}
        <Box sx={{ py: 10, bgcolor: "grey.100" }}>
          <Container maxWidth="lg">
            <ParallaxSection>
              <Typography variant="h3" align="center" gutterBottom>
                What Our Client Says
              </Typography>
            </ParallaxSection>
            <GlowingCard sx={{ maxWidth: "800px", mx: "auto" }}>
              <CardContent>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontStyle: "italic" }}
                >
                  "The automation solution transformed how we manage our
                  infrastructure. The integration of network and database
                  services with end-to-end compliance has streamlined our
                  operations significantly."
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  – CTO, Saudi's Leading Digital Enterprise
                </Typography>
              </CardContent>
            </GlowingCard>
          </Container>
        </Box>

        {/* Call to Action Section */}
        <Box
          sx={{
            py: 10,
            bgcolor: "black",
            color: "white",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <motion.div
            style={{
              position: "absolute",
              inset: 0,
              opacity: 0.2,
            }}
            animate={{
              background: [
                "radial-gradient(circle at 0% 0%, #22D3EE 0%, transparent 50%)",
                "radial-gradient(circle at 100% 100%, #22D3EE 0%, transparent 50%)",
                "radial-gradient(circle at 0% 0%, #22D3EE 0%, transparent 50%)",
              ],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <Container
            maxWidth="lg"
            sx={{ textAlign: "center", position: "relative", zIndex: 1 }}
          >
            <Typography variant="h3" gutterBottom>
              Discover How Automation Can Transform Your Infrastructure
            </Typography>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Link to="/Services">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<ChevronRight />}
                  sx={{
                    mt: 2,
                    py: 1.5,
                    px: 4,
                    borderRadius: "9999px",
                    "&:hover": {
                      transform: "scale(1.05)",
                      transition: "transform 0.3s",
                    },
                  }}
                >
                  Explore Our Services
                </Button>
              </Link>
            </motion.div>
          </Container>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default SaudiDigitalLeaderSuccessStory;
