"use client";

import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Lightbulb,
  PenTool,
  Wrench,
  GraduationCap,
  Server,
  ArrowRightCircle,
  Database,
  Shield,
  Cloud,
  Layers,
  ChevronDown,
} from "lucide-react";

const expertiseAreas = [
  {
    title: "Consult",
    subtitle: "Guiding You to Success",
    icon: Lightbulb,
    items: [
      "Discovery Workshops: Identify business goals, challenges, and opportunities.",
      "Review Sessions: Validate strategies and refine objectives.",
      "Requirement Gathering: Define technical and business needs.",
      "Collaborative Design Sessions: Shape tailored solutions with your team.",
    ],
  },
  {
    title: "Design",
    subtitle: "Strategizing for Excellence",
    icon: PenTool,
    items: [
      "Craft designs based on real-world constraints and validated assumptions.",
      "Focus on mitigating risks while enhancing scalability, security, manageability, and simplicity.",
      "Deliver design blueprints aligned with your operational and strategic needs.",
    ],
  },
  {
    title: "Build",
    subtitle: "From Vision to Reality",
    icon: Wrench,
    items: [
      "Deploy robust infrastructure using best practices from trusted vendors.",
      "Perform comprehensive testing and validation to ensure reliability.",
      "Provide detailed as-built documentation and operational guides.",
      "Execute with efficiency, leveraging extensive experience across domains.",
    ],
  },
  {
    title: "Train",
    subtitle: "Empowering Your Team",
    icon: GraduationCap,
    items: [
      "Provide hands-on onboarding to seamlessly adopt new solutions.",
      "Deliver customized training programs tailored to your organization's needs.",
      "Share insights and practical applications based on field-proven expertise.",
    ],
  },
];

const specializedServices = [
  {
    title: "Datacenter & Operations",
    icon: Server,
    description:
      "Enterprise-grade infrastructure solutions focusing on virtualization, HCI, and automated operations to streamline IT management.",
  },
  {
    title: "Workload Migrations",
    icon: ArrowRightCircle,
    description:
      "Seamlessly transition workloads across platforms, supporting diverse environments like P2V, V2V, X2V, and X2C.",
  },
  {
    title: "Business Continuity & Disaster Recovery",
    icon: Shield,
    description:
      "Design and deploy resilient solutions with automated failover, RPO/RTO alignment, and comprehensive DR runbooks.",
  },
  {
    title: "Database Modernization",
    icon: Database,
    description:
      "Transform databases with services like Database-as-a-Service, migration planning, and fit checks to optimize performance.",
  },
  {
    title: "Cloud Automations",
    icon: Cloud,
    description:
      "Automate cloud solutions for private and hybrid clouds, enhancing efficiency with integrated application workflows and process automation.",
  },
  {
    title: "App Modernization",
    icon: Layers,
    description:
      "Modernize applications using containerization, Kubernetes, and CI/CD pipelines to align with cloud-first strategies.",
  },
];

export default function OurExpertiseHero() {
  const [activeExpertise, setActiveExpertise] = useState(expertiseAreas[0]);
  const [expandedService, setExpandedService] = useState(null);

  const toggleService = (index) => {
    setExpandedService(expandedService === index ? null : index);
  };

  return (
    <section className="min-h-screen bg-gradient-to-br from-indigo-900 via-purple-900 to-pink-900 text-white py-20 overflow-hidden">
      <div className="container mx-auto px-4">
        <motion.h1
          className="text-3xl md:text-4xl font-bold text-center mb-12"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Our Expertise
        </motion.h1>

        <div className="grid md:grid-cols-2 gap-12">
          <div>
            <div className="flex flex-wrap gap-4 mb-8">
              {expertiseAreas.map((area) => (
                <motion.button
                  key={area.title}
                  className={`px-4 py-2 rounded-full text-sm font-semibold transition-colors ${
                    activeExpertise.title === area.title
                      ? "bg-white text-indigo-900"
                      : "bg-indigo-800 hover:bg-indigo-700"
                  }`}
                  onClick={() => setActiveExpertise(area)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {area.title}
                </motion.button>
              ))}
            </div>
            <AnimatePresence mode="wait">
              <motion.div
                key={activeExpertise.title}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="text-3xl font-bold mb-4 flex items-center">
                  <activeExpertise.icon className="w-8 h-8 mr-2" />
                  {activeExpertise.title}
                </h2>
                <h3 className="text-xl text-pink-300 mb-4">
                  {activeExpertise.subtitle}
                </h3>
                <ul className="space-y-2">
                  {activeExpertise.items.map((item, index) => (
                    <motion.li
                      key={index}
                      className="flex items-start"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                      <span className="text-pink-300 mr-2">•</span>
                      {item}
                    </motion.li>
                  ))}
                </ul>
              </motion.div>
            </AnimatePresence>
          </div>
          <div>
            <h2 className="text-3xl font-bold mb-8">Specialized Services</h2>
            <div className="space-y-4">
              {specializedServices.map((service, index) => (
                <motion.div
                  key={service.title}
                  className="bg-indigo-800 bg-opacity-50 rounded-lg overflow-hidden"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <button
                    className="w-full p-4 text-left flex items-center justify-between"
                    onClick={() => toggleService(index)}
                  >
                    <span className="text-xl font-semibold flex items-center">
                      <service.icon className="w-6 h-6 mr-2 text-pink-300" />
                      {service.title}
                    </span>
                    <ChevronDown
                      className={`w-5 h-5 transition-transform ${
                        expandedService === index ? "transform rotate-180" : ""
                      }`}
                    />
                  </button>
                  <AnimatePresence>
                    {expandedService === index && (
                      <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        transition={{ duration: 0.3 }}
                        className="overflow-hidden"
                      >
                        <p className="p-4 text-indigo-200">
                          {service.description}
                        </p>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
