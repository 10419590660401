import React from "react";

const Input = ({
  label,
  placeholder,
  value,
  onChange,
  type = "text",
  ...props
}) => {
  return (
    <div className="mb-4">
      {label && <label className="block text-gray-700 mb-2">{label}</label>}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="border rounded-md p-2 w-full"
        {...props}
      />
    </div>
  );
};

export default Input;
