import React, { useState , useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import logo from "../../assets/AvancerPI_Symbol.svg";
import {
  Users,
  BarChart3,
  PieChart,
  Activity,
  Mail,
  Clock,
  ArrowUpRight,
  ArrowDownRight,
  Search,
  LogOut,
  FileText,
  Briefcase,
  Settings,
  Bell,
  Shield,
  MessageSquare,
  TrendingUp,
  LineChart,
  Database,
  Eye,
  Clock4,
  ArrowRight,
  MapPin,
  Monitor,
  Tablet,
  SmartphoneIcon as MobileIcon,
  Chrome,
  ChromeIcon as Firefox,
  AppleIcon as Safari,
  FileCheck,
  UserCheck,
  Building2,
  GraduationCap,
} from "lucide-react";
import Button from "../../components/button";
import Input from "../../components/Input";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "../../components/Card";

// import {
//   DropdownMenu,
//   DropdownMenuContent,
//   DropdownMenuItem,
//   DropdownMenuTrigger,
// } from "@/components/ui/dropdown-menu";

// Mock data for different sections

// const [data, setData] = useState([])





const visitorMetrics = {
  devices: [
    { name: "Desktop", value: 45, icon: Monitor },
    { name: "Mobile", value: 40, icon: MobileIcon },
    { name: "Tablet", value: 15, icon: Tablet },
  ],
  browsers: [
    { name: "Chrome", value: 55, icon: Chrome },
    { name: "Firefox", value: 20, icon: Firefox },
    { name: "Safari", value: 25, icon: Safari },
  ],
  topPages: [
    { name: "Home", views: 1250, avgTime: "2m 45s" },
    { name: "Services", views: 980, avgTime: "3m 20s" },
    { name: "Success Stories", views: 850, avgTime: "4m 15s" },
    { name: "Contact", views: 620, avgTime: "1m 55s" },
  ],
};

const jobApplications = {
  total: 450,
  new: 45,
  reviewed: 125,
  shortlisted: 80,
  rejected: 200,
  byRole: [
    { role: "Cloud Engineer", count: 150 },
    { role: "DevOps Engineer", count: 120 },
    { role: "Solution Architect", count: 100 },
    { role: "Database Administrator", count: 80 },
  ],
};

const serviceEngagement = [
  { name: "Cloud Migration", views: 2500, inquiries: 120 },
  { name: "Disaster Recovery", views: 2100, inquiries: 95 },
  { name: "Database Services", views: 1800, inquiries: 85 },
  { name: "DevOps Automation", views: 1600, inquiries: 75 },
];

// Dashboard Sections Components
const DashboardHighlights = () => {

  const [data, setData] = useState(null); // State to store fetched data
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
        try {
            setLoading(true); // Start loading
            const response = await fetch('/api/get_UserData'); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json(); // Parse the JSON response
            setData(result); // Set the fetched data
        } catch (error) {
            setError(error.message); // Catch and set any errors
        } finally {
            setLoading(false); // End loading
        }
    };
    fetchData(); 
    }, []);

    console.log(data);
    

    const highlightMetrics = [
      {
        title: "Total Visitors Today",
        value: "2,547",
        change: "+12.5%",
        icon: Users,
        color: "bg-blue-500",
      },
      {
        title: "Avg. Session Duration",
        value: "4m 32s",
        change: "+8.2%",
        icon: Clock,
        color: "bg-green-500",
      },
      {
        title: "Job Applications",
        value: "127",
        change: "+15.3%",
        icon: Briefcase,
        color: "bg-purple-500",
      },
      {
        title: "Contact Requests",
        value: "45",
        change: "+5.7%",
        icon: Mail,
        color: "bg-orange-500",
      },
    ];

  return (
    <div className="grid gap-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {highlightMetrics.map((metric, index) => (
          <motion.div
            key={metric.title}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <Card className="p-6 relative overflow-hidden">
              <div
                className={`absolute right-0 top-0 w-24 h-24 -mr-8 -mt-8 rounded-full opacity-10 ${metric.color}`}
              />
              <metric.icon className="h-8 w-8 mb-4 text-muted-foreground" />
              <h3 className="text-sm font-medium text-muted-foreground">
                {metric.title}
              </h3>
              <div className="mt-2 flex items-baseline">
                <p className="text-2xl font-semibold">{metric.value}</p>
                <span className="ml-2 text-sm text-green-500">
                  {metric.change}
                </span>
              </div>
            </Card>
          </motion.div>
        ))}
      </div>

      <div className="grid md:grid-cols-2 gap-6">
        <Card className="p-6">
          <h3 className="font-semibold mb-4">Recent Activity</h3>
          <div className="space-y-4">
            {[...Array(5)].map((_, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center space-x-4"
              >
                <div className="h-2 w-2 rounded-full bg-purple-500" />
                <div className="flex-1">
                  <p className="text-sm">
                    New visitor from{" "}
                    <span className="font-medium">United States</span>
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Viewed Cloud Migration Services
                  </p>
                </div>
                <div className="text-sm text-muted-foreground">
                  {5 - index}m ago
                </div>
              </motion.div>
            ))}
          </div>
        </Card>

        <Card className="p-6">
          <h3 className="font-semibold mb-4">Service Engagement</h3>
          <div className="space-y-4">
            {serviceEngagement.map((service, index) => (
              <motion.div
                key={service.name}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="space-y-2"
              >
                <div className="flex justify-between text-sm">
                  <span>{service.name}</span>
                  <span>{service.views} views</span>
                </div>
                <div className="h-2 rounded-full bg-muted overflow-hidden">
                  <motion.div
                    className="h-full bg-purple-500"
                    initial={{ width: 0 }}
                    animate={{ width: `${(service.views / 3000) * 100}%` }}
                    transition={{ delay: 0.5 + index * 0.1 }}
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
};

const VisitorInsights = () => {
  return (
    <div className="space-y-6">
      <div className="grid md:grid-cols-2 gap-6">
        <Card className="p-6">
          <h3 className="font-semibold mb-6">Device Distribution</h3>
          <div className="space-y-6">
            {visitorMetrics.devices.map((device, index) => (
              <motion.div
                key={device.name}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center"
              >
                <device.icon className="h-5 w-5 mr-4 text-muted-foreground" />
                <div className="flex-1">
                  <div className="flex justify-between mb-2">
                    <span>{device.name}</span>
                    <span>{device.value}%</span>
                  </div>
                  <div className="h-2 rounded-full bg-muted overflow-hidden">
                    <motion.div
                      className="h-full bg-purple-500"
                      initial={{ width: 0 }}
                      animate={{ width: `${device.value}%` }}
                      transition={{ delay: 0.5 }}
                    />
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </Card>

        <Card className="p-6">
          <h3 className="font-semibold mb-6">Browser Usage</h3>
          <div className="space-y-6">
            {visitorMetrics.browsers.map((browser, index) => (
              <motion.div
                key={browser.name}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center"
              >
                <browser.icon className="h-5 w-5 mr-4 text-muted-foreground" />
                <div className="flex-1">
                  <div className="flex justify-between mb-2">
                    <span>{browser.name}</span>
                    <span>{browser.value}%</span>
                  </div>
                  <div className="h-2 rounded-full bg-muted overflow-hidden">
                    <motion.div
                      className="h-full bg-purple-500"
                      initial={{ width: 0 }}
                      animate={{ width: `${browser.value}%` }}
                      transition={{ delay: 0.5 }}
                    />
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </Card>
      </div>

      <Card className="p-6">
        <h3 className="font-semibold mb-6">Most Viewed Pages</h3>
        <div className="space-y-4">
          {visitorMetrics.topPages.map((page, index) => (
            <motion.div
              key={page.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="flex items-center justify-between p-4 rounded-lg bg-muted/50"
            >
              <div>
                <h4 className="font-medium">{page.name}</h4>
                <p className="text-sm text-muted-foreground">
                  {page.views} views
                </p>
              </div>
              <div className="text-right">
                <p className="text-sm font-medium">{page.avgTime}</p>
                <p className="text-sm text-muted-foreground">Avg. Time</p>
              </div>
            </motion.div>
          ))}
        </div>
      </Card>
    </div>
  );
};

const JobApplicationsView = () => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {[
          {
            label: "Total Applications",
            value: jobApplications.total,
            icon: FileCheck,
          },
          { label: "New", value: jobApplications.new, icon: UserCheck },
          {
            label: "Shortlisted",
            value: jobApplications.shortlisted,
            icon: Building2,
          },
          {
            label: "Hired",
            value: jobApplications.reviewed,
            icon: GraduationCap,
          },
        ].map((metric, index) => (
          <motion.div
            key={metric.label}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <Card className="p-6">
              <metric.icon className="h-8 w-8 mb-4 text-muted-foreground" />
              <h3 className="text-sm font-medium text-muted-foreground">
                {metric.label}
              </h3>
              <p className="text-2xl font-semibold mt-2">{metric.value}</p>
            </Card>
          </motion.div>
        ))}
      </div>

      <Card className="p-6">
        <h3 className="font-semibold mb-6">Applications by Role</h3>
        <div className="space-y-6">
          {jobApplications.byRole.map((role, index) => (
            <motion.div
              key={role.role}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="space-y-2"
            >
              <div className="flex justify-between">
                <span>{role.role}</span>
                <span>{role.count} applications</span>
              </div>
              <div className="h-2 rounded-full bg-muted overflow-hidden">
                <motion.div
                  className="h-full bg-purple-500"
                  initial={{ width: 0 }}
                  animate={{
                    width: `${(role.count / jobApplications.total) * 100}%`,
                  }}
                  transition={{ delay: 0.5 }}
                />
              </div>
            </motion.div>
          ))}
        </div>
      </Card>
    </div>
  );
};

// Main Dashboard Component
const Dashboard = () => {
  const [activeSection, setActiveSection] = useState("highlights");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const renderContent = () => {
    switch (activeSection) {
      case "highlights":
        return <DashboardHighlights />;
      case "visitors":
        return <VisitorInsights />;
      case "jobs":
        return <JobApplicationsView />;
      default:
        return <DashboardHighlights />;
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <div className="flex">
        {/* Sidebar */}
        <div className="w-64 border-r bg-muted/50 p-4 space-y-4">
          <div className="space-y-2">
            {[
              { id: "highlights", label: "Dashboard", icon: BarChart3 },
              { id: "visitors", label: "Visitor Insights", icon: Users },
              { id: "jobs", label: "Job Applications", icon: Briefcase },
              { id: "services", label: "Services", icon: FileText },
              { id: "security", label: "Security", icon: Shield },
              { id: "settings", label: "Settings", icon: Settings },
            ].map((item) => (
              <button
                key={item.id}
                variant={activeSection === item.id ? "secondary" : "ghost"}
                className={`w-full flex items-center justify-start p-2 text-sm font-medium rounded-md transition-colors 
                    ${
                      activeSection === item.id
                        ? "bg-gray-200 text-blue-700"
                        : "hover:bg-gray-100 text-gray-600"
                    }`}
                onClick={() => setActiveSection(item.id)}
              >
                <item.icon className="mr-3 h-5 w-5" />
                {item.label}
              </button>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-auto">
          <header className="border-b bg-white shadow-sm">
            <div className="flex h-16 items-center px-6">
              <h2 className="text-lg font-semibold text-gray-800">
                AvancerPI Analytics
              </h2>
              <div className="ml-auto flex items-center space-x-6">
                <input
                  type="search"
                  placeholder="Search..."
                  className="w-[200px] px-3 py-2 rounded-md border border-gray-300 text-sm shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <div className="relative">
                  <button
                    onClick={toggleDropdown}
                    className="h-8 w-8 hover:ring-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <img alt="Avatar" className="rounded-full" src={logo} />
                  </button>

                  {isDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-40 rounded-md bg-white shadow-lg border border-gray-200">
                      <button className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        <LogOut className="mr-2 h-4 w-4" />
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </header>

          <main className="p-6">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeSection}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
              >
                {renderContent()}
              </motion.div>
            </AnimatePresence>
          </main>
        </div>
      </div>
    </div>
  );
};

// Login Form Component
// const LoginForm = ({ onLogin }) => {
//   return (
//     <motion.div
//       initial={{ opacity: 0, y: 20 }}
//       animate={{ opacity: 1, y: 0 }}
//       className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-500/10 via-blue-500/10 to-pink-500/10"
//     >
//       <Card className="w-full max-w-md p-8 space-y-6">
//         <div className="text-center space-y-2">
//           <h1 className="text-3xl font-bold">Welcome Back</h1>
//           <p className="text-muted-foreground">
//             Enter your credentials to access the dashboard
//           </p>
//         </div>
//         <div className="space-y-4">
//           <div className="space-y-2">
//             <Input type="email" placeholder="Email" />
//           </div>
//           <div className="space-y-2">
//             <Input type="password" placeholder="Password" />
//           </div>
//           <Button className="w-full" onClick={onLogin}>
//             Sign In
//           </Button>
//         </div>
//       </Card>
//     </motion.div>
//   );
// };

// Main Component
export default function Component() {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <AnimatePresence mode="wait">
      <Dashboard />
    </AnimatePresence>
  );
}
