import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  motion,
  useAnimation,
  useInView,
  useScroll,
  useTransform,
} from "framer-motion";
import {
  Clock,
  DollarSign,
  Shield,
  Cloud,
  Server,
  CheckCircle,
  ChevronRight,
} from "lucide-react";
import HeaderNav from "../HeaderNav";
import Footer from "../Footer";
import CustomerSuccessStory from "../../assets/SuccessStories/CustomerSuccessStory.jpg";
const ParallaxSection = ({ children, className = "" }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const y = useTransform(scrollYProgress, [0, 1], [50, -50]);

  return (
    <motion.div ref={ref} style={{ y }} className={className}>
      {children}
    </motion.div>
  );
};

const AnimatedCounter = ({ target, duration = 2 }) => {
  const nodeRef = useRef(null);
  const controls = useAnimation();
  const inView = useInView(nodeRef);

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.5 },
      });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={nodeRef}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={controls}
    >
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: duration, ease: "easeOut" }}
      >
        {target}
      </motion.span>
      <span>%</span>
    </motion.div>
  );
};

const GlowingCard = ({ children, className = "" }) => (
  <motion.div
    className={`relative group ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ duration: 0.3 }}
  >
    <motion.div
      className="absolute inset-0 bg-gradient-to-r from-cyan-500/20 to-blue-500/20 rounded-lg blur-xl group-hover:opacity-75 transition-opacity duration-500 opacity-0"
      animate={{
        scale: [1, 1.1, 1],
        opacity: [0.5, 0.7, 0.5],
      }}
      transition={{
        duration: 4,
        repeat: Infinity,
        ease: "linear",
      }}
    />
    {children}
  </motion.div>
);

export default function Component() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderNav />
      <div className="min-h-screen bg-white">
        {/* Hero Section */}
        <section className="relative h-[500px] md:min-h-screen bg-gray-900 flex items-end justify-center overflow-hidden">
          <img
            src={CustomerSuccessStory}
            alt="Global Telecom Solutions Provider"
            className="absolute inset-0 w-full h-full object-cover z-0"
          />
          {/* Overlay Animation */}
          <motion.div
            className="absolute inset-0 z-0"
            animate={{
              backgroundColor: [
                "rgba(0,0,0,0.3)",
                "rgba(0,0,0,0.5)",
                "rgba(0,0,0,0.3)",
              ],
            }}
            transition={{
              duration: 5,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <div className="container  relative mx-auto px-4 z-10">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.2 }}
              className="max-w-4xl mx-auto text-center mb-4 p-6 rounded-lg bg-black bg-opacity-50"
            >
              <motion.h1
                className="text-3xl md:text-5xl lg:text-6xl font-bold mb-4 text-white [text-shadow:_0_2px_10px_rgb(0_0_0_/_20%)]"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                Empowering One of India's Leading Energy Giants with Private
                Cloud Innovation
              </motion.h1>
              <motion.p
                className="text-xl md:text-2xl lg:text-3xl text-white mb-8"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                A transformative journey to operational efficiency, scalability,
                and governance
              </motion.p>
            </motion.div>
          </div>
        </section>

        {/* Background and Challenge Section */}
        <motion.section
          className="py-20 bg-gray-50"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <div className="container mx-auto px-4">
            <ParallaxSection className="mb-12">
              <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-900">
                Background and Challenge
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-2 gap-8">
              <GlowingCard className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
                  Background
                </h3>
                <p className="text-base md:text-lg text-gray-600">
                  A leading Indian energy enterprise sought to revolutionize its
                  IT infrastructure. Their vision: a robust, scalable private
                  cloud ecosystem that would enhance governance, automate
                  operations, and provide seamless control across Nutanix and
                  VMware platforms.
                </p>
              </GlowingCard>
              <GlowingCard className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
                  Challenges
                </h3>
                <ul className="space-y-4">
                  <li className="flex items-center group">
                    <Cloud className="mr-2 text-cyan-600 flex-shrink-0 transition-transform duration-300 group-hover:scale-110" />
                    <span className="text-gray-600">
                      Establish a private cloud supporting critical operations
                      across diverse platforms
                    </span>
                  </li>
                  <li className="flex items-center group">
                    <Server className="mr-2 text-cyan-600 flex-shrink-0 transition-transform duration-300 group-hover:scale-110" />
                    <span className="text-gray-600">
                      Implement automation for deployment, monitoring, and
                      maintenance tasks
                    </span>
                  </li>
                  <li className="flex items-center group">
                    <CheckCircle className="mr-2 text-cyan-600 flex-shrink-0 transition-transform duration-300 group-hover:scale-110" />
                    <span className="text-gray-600">
                      Develop custom workflows for approvals, notifications, and
                      governance
                    </span>
                  </li>
                </ul>
              </GlowingCard>
            </div>
          </div>
        </motion.section>

        {/* Solution Section */}
        <motion.section
          className="py-20 bg-black text-white relative overflow-hidden"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <motion.div
            className="absolute inset-0 opacity-20"
            animate={{
              background: [
                "radial-gradient(circle at 30% 30%, #22D3EE 0%, transparent 60%)",
                "radial-gradient(circle at 70% 70%, #22D3EE 0%, transparent 60%)",
                "radial-gradient(circle at 30% 30%, #22D3EE 0%, transparent 60%)",
              ],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <div className="container mx-auto px-4 relative z-10">
            <ParallaxSection>
              <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
                Our Solution
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  title: "Hybrid Private Cloud Deployment",
                  description:
                    "Deployed a scalable private cloud on Nutanix and VMware, ensuring seamless integration.",
                  icon: Cloud,
                },
                {
                  title: "Day 1 and Day 2 Automation",
                  description:
                    "Automated provisioning, monitoring, and performance optimization to enhance efficiency.",
                  icon: Server,
                },
                {
                  title: "Business Process Workflows",
                  description:
                    "Designed custom workflows for approvals, notifications, and governance to ensure compliance.",
                  icon: CheckCircle,
                },
              ].map((item, index) => (
                <GlowingCard
                  key={index}
                  className="bg-white/10 backdrop-blur-sm p-6 rounded-lg"
                >
                  <item.icon className="w-12 h-12 mb-4 text-cyan-400" />
                  <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                  <p className="text-gray-300">{item.description}</p>
                </GlowingCard>
              ))}
            </div>
          </div>
        </motion.section>

        {/* Impact Section */}
        <motion.section
          id="impact"
          className="py-20 bg-white"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <div className="container mx-auto px-4">
            <ParallaxSection>
              <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-gray-900">
                The Impact
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-3 gap-8 mb-12">
              {[
                { value: 95, label: "Faster Operations", icon: Clock },
                { value: 30, label: "Cost Savings", icon: DollarSign },
                { value: 100, label: "Governance Compliance", icon: Shield },
              ].map((item, index) => (
                <GlowingCard
                  key={index}
                  className="text-center bg-gray-50 p-6 rounded-lg shadow-lg"
                >
                  <item.icon className="w-12 h-12 mx-auto mb-4 text-cyan-600" />
                  <div className="text-3xl md:text-4xl font-bold mb-2 text-gray-900">
                    <AnimatedCounter target={item.value} />
                  </div>
                  <p className="text-lg md:text-xl text-gray-600">
                    {item.label}
                  </p>
                </GlowingCard>
              ))}
            </div>
            <motion.p
              className="text-base md:text-lg text-center max-w-3xl mx-auto text-gray-600"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              Our solution dramatically reduced operational delays, optimized
              costs, and achieved full governance compliance. These improvements
              positioned the organization for sustained growth and innovation in
              the dynamic energy sector.
            </motion.p>
          </div>
        </motion.section>

        {/* Testimonial Section */}
        <motion.section
          className="py-20 bg-gray-50"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <div className="container mx-auto px-4">
            <ParallaxSection>
              <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-gray-900">
                What Our Client Says
              </h2>
            </ParallaxSection>
            <GlowingCard className="bg-white p-8 rounded-lg shadow-lg max-w-3xl mx-auto">
              <p className="text-lg md:text-xl italic mb-4 text-gray-600">
                "The team delivered a cutting-edge private cloud solution that
                transformed our operations. The level of automation and
                governance provided has significantly streamlined our workflows
                and enhanced efficiency. This implementation has been a
                game-changer for our IT infrastructure."
              </p>
              <p className="text-base md:text-lg font-semibold text-gray-900">
                – IT Head, Leading Indian Energy Enterprise
              </p>
            </GlowingCard>
          </div>
        </motion.section>

        {/* Call to Action Section */}
        <motion.section
          className="py-20 bg-black text-white relative overflow-hidden"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <motion.div
            className="absolute inset-0 opacity-20"
            animate={{
              background: [
                "radial-gradient(circle at 0% 0%, #22D3EE 0%, transparent 50%)",
                "radial-gradient(circle at 100% 100%, #22D3EE 0%, transparent 50%)",
                "radial-gradient(circle at 0% 0%, #22D3EE 0%, transparent 50%)",
              ],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <div className="container mx-auto px-4 text-center relative z-10">
            <h2 className="text-3xl md:text-4xl font-bold mb-8">
              Discover How We Can Transform Your Infrastructure
            </h2>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <HashLink
                to="/Home#SuccessStories"
                className="bg-cyan-600 hover:bg-cyan-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 transform hover:scale-105 inline-flex items-center"
              >
                Explore More Success Stories
                <ChevronRight className="ml-2 h-5 w-5" />
              </HashLink>
            </motion.div>
          </div>
        </motion.section>
      </div>
      <Footer />
    </>
  );
}
