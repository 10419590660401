import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion"; // Ensure framer-motion is installed

import {
  Database,
  Briefcase,
  Target,
  Server,
  ChevronDown,
  ChevronRight,
  ChevronLeft,
  Menu,
  X,
  Cloud,
  Shield,
  Settings,
  Code,
  Laptop,
  Mail,
  Phone,
  MapPin,
  Linkedin,
  Twitter,
  Facebook,
  Instagram,
  Youtube,
  Users,
  BarChart,
  Box,
  Cpu,
  GitBranch,
  Rocket,
  ArrowUpCircle,
  Shuffle,
  HardDrive,
  Network,
  Zap,
  Layers,
  Workflow,
  FileCode,
  RefreshCw,
  Clock,
  FileCheck,
  Repeat,
  ChevronUp,
  Cog,
} from "lucide-react";

const SubServicesModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { subserviceName } = useParams();

  const subServices = [
    {
      key: "virtualization",
      name: "Virtualization",
      icon: Box,
      description:
        "Virtualization is the process of running multiple virtual machines (VMs) on a single physical hardware resource, enhancing resource efficiency and flexibility. By creating isolated environments for each workload, virtualization enables users to maximize hardware use and reduce costs. Our virtualization process involves:\n\n" +
        "1. **Consultation**: We begin by evaluating your current infrastructure, workload requirements, and business goals. This helps us choose the best virtualization technology (e.g., VMware, Hyper-V, KVM) based on performance, scalability, and cost-efficiency.\n" +
        "2. **Design**: We develop a comprehensive virtualization architecture that includes resource allocation, storage, network design, and security policies to ensure optimal VM performance and data protection.\n" +
        "3. **Implementation**: Our team sets up the virtualization environment, configuring hypervisors, virtual networks, storage systems, and creating templates for quick VM provisioning.\n" +
        "4. **Training & Support**: After deployment, we provide training for your IT team on managing virtual resources and offer ongoing support, performance monitoring, and optimization.",
    },
    {
      key: "hci",
      name: "Hyper-Converged Infrastructure (HCI)",
      icon: Database,
      description:
        "Hyper-Converged Infrastructure (HCI) integrates compute, storage, and networking into a single system that reduces complexity and streamlines management. Our HCI implementation process includes:\n\n" +
        "1. **Assessment**: We analyze your current infrastructure to identify areas where HCI can simplify operations, increase resource efficiency, and reduce costs.\n" +
        "2. **Design**: Our team designs a modular and scalable HCI architecture tailored to your business needs, focusing on workload performance, fault tolerance, and future scalability.\n" +
        "3. **Deployment**: We deploy HCI components—such as software-defined storage, virtualized networks, and integrated compute—ensuring seamless interoperability and optimal performance.\n" +
        "4. **Ongoing Support**: We provide regular monitoring, updates, and troubleshooting to ensure the HCI environment consistently meets operational demands.",
    },
    {
      key: "management",
      name: "Management",
      icon: Briefcase,
      description:
        "Effective IT management tools and strategies are essential for maintaining uptime and operational efficiency. We offer comprehensive management solutions that include:\n\n" +
        "1. **Analysis**: We start by reviewing your current management tools, processes, and pain points, identifying areas where efficiency and visibility can be improved.\n" +
        "2. **Strategy Development**: We develop a tailored management strategy, implementing tools that support real-time monitoring, automated workflows, and proactive issue resolution.\n" +
        "3. **Implementation**: Our team integrates the selected tools and configures dashboards, alerts, and reporting structures to minimize manual intervention and increase visibility.\n" +
        "4. **Training & Support**: We offer training to your team on using these tools effectively and provide ongoing support to adapt to any changes in your operational requirements.",
    },
    {
      key: "operations",
      name: "Operations",
      icon: Target,
      description:
        "Efficient operations are crucial to maintaining business continuity and reducing operational costs. We enhance operational processes through:\n\n" +
        "1. **Process Review**: We assess current workflows and procedures, identifying gaps in efficiency, security, and manageability.\n" +
        "2. **Optimization**: We streamline processes, introducing automation where applicable to reduce overhead and enhance consistency.\n" +
        "3. **Implementation**: Optimized workflows and tools are deployed, ensuring seamless integration with your existing environment.\n" +
        "4. **Continuous Improvement**: We conduct periodic reviews to ensure that operational processes evolve with changing business needs and technological advancements.",
    },
    {
      key: "infrastructure_automation",
      name: "Infrastructure Automation",
      icon: Zap,
      description:
        "Infrastructure automation reduces manual tasks, accelerates deployment times, and optimizes resource allocation. Our approach includes:\n\n" +
        "1. **Assessment**: We evaluate repetitive tasks and processes to identify areas for automation.\n" +
        "2. **Tool Selection**: We recommend and configure automation tools (e.g., Ansible, Terraform) that suit your specific infrastructure and operational requirements.\n" +
        "3. **Workflow Automation**: Custom workflows are designed and automated, handling tasks such as provisioning, scaling, and updating infrastructure.\n" +
        "4. **Monitoring and Support**: We provide monitoring for automated tasks, ensuring accuracy and reliability. Regular adjustments are made to enhance efficiency further.",
    },
    {
      key: "availability",
      name: "Local & Remote Site Availability",
      icon: Server,
      description:
        "Ensuring site availability protects business continuity by minimizing downtime and data loss. Our solutions include:\n\n" +
        "1. **Needs Analysis**: We determine your Recovery Point Objectives (RPO), Recovery Time Objectives (RTO), and Service Level Agreements (SLA) to design a solution that aligns with your continuity requirements.\n" +
        "2. **Design**: We architect a resilient system that supports both local and remote site availability, incorporating redundant storage, networking, and compute resources.\n" +
        "3. **Deployment**: We implement and configure failover and replication systems, ensuring minimal service interruption during site transitions.\n" +
        "4. **Testing & Monitoring**: Regular testing and performance monitoring ensure high availability, with rapid response to issues or outages.",
    },
    {
      key: "rpo_rto_sla",
      name: "RPO, RTO, SLA",
      icon: Target,
      description:
        "RPO (Recovery Point Objective), RTO (Recovery Time Objective), and SLA (Service Level Agreements) are essential metrics for disaster recovery and continuity planning. Our process includes:\n\n" +
        "1. **Goal Setting**: We work with your team to establish realistic RPOs and RTOs based on your SLA requirements and business priorities.\n" +
        "2. **Planning**: A comprehensive recovery plan is developed to address data recovery, downtime minimization, and continuity needs.\n" +
        "3. **Implementation**: Backup, replication, and failover solutions are implemented to meet RPO and RTO targets.\n" +
        "4. **Review & Adjustments**: Regular reviews ensure recovery objectives remain aligned with your business evolution.",
    },
    {
      key: "runbooks",
      name: "Runbooks",
      icon: FileCheck,
      description:
        "Runbooks are structured, step-by-step instructions that guide teams during incidents and routine tasks, ensuring consistent and effective responses. Our runbook creation process includes:\n\n" +
        "1. **Documentation**: We document tasks and procedures, creating clear and detailed steps for incident response and operational tasks.\n" +
        "2. **Review & Approval**: Runbooks undergo review to ensure they meet operational requirements and can be followed accurately by staff.\n" +
        "3. **Training**: Staff are trained on using runbooks effectively, minimizing response times during incidents.\n" +
        "4. **Updates**: Regular updates are conducted to keep runbooks relevant to evolving workflows and systems.",
    },
    {
      key: "failover_failback",
      name: "Failover & Failback",
      icon: Repeat,
      description:
        "Failover and failback solutions enable seamless operation during infrastructure failures. Our approach includes:\n\n" +
        "1. **System Design**: We design failover architectures that ensure continuity by transferring workloads automatically during an outage.\n" +
        "2. **Implementation**: Our team configures failover and failback mechanisms, including load balancers and replication systems.\n" +
        "3. **Testing**: Comprehensive testing ensures failover solutions work effectively under various failure scenarios.\n" +
        "4. **Training & Documentation**: We provide training and documentation on managing failover and failback operations.",
    },
    {
      key: "database_migrations",
      name: "Database Migrations",
      icon: Shuffle,
      description:
        "Database migrations enable a transition to newer platforms or environments with minimal data loss and downtime. Our database migration process includes:\n\n" +
        "1. **Assessment**: We assess your database structure, dependencies, and migration requirements to ensure smooth transitions.\n" +
        "2. **Planning**: We create a migration plan that includes backup strategies, downtime estimation, and risk mitigation.\n" +
        "3. **Execution**: Our team executes the migration, ensuring data integrity and minimal disruption to operations.\n" +
        "4. **Post-Migration**: After migration, we verify system functionality, performance, and data consistency.",
    },
  ];

  const activeSubService = subServices.find(
    (subservice) => subservice.key === subserviceName
  );

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Modal
          title={activeSubService.name}
          open={isModalOpen}
          onCancel={closeModal}
          onOk={closeModal}
          footer={null}
        >
          <div className="flex items-center mb-4">
            <activeSubService.icon
              style={{ fontSize: "24px", marginRight: "8px" }}
            />
            <h3 className="text-xl font-semibold">{activeSubService.name}</h3>
          </div>
          <p>{activeSubService.description}</p>
        </Modal>
      </motion.div>
    </div>
  );
};

export default SubServicesModal;
