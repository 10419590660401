import React, { useState, useEffect, useRef } from "react";
// import Image from "next/image";
// import Link from "next/link";
import { motion, AnimatePresence } from "framer-motion";
import Button from "../components/button";
import Input from "../components/Input";
import { Link } from "react-router-dom";
import HeaderNav from "./HeaderNav";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import { Loader2 } from "lucide-react";
import axios from "axios";

import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Menu,
  X,
  Phone,
  Mail,
  MapPin,
  Clock,
  Linkedin,
  Twitter,
  Facebook,
  Send,
  ArrowRight,
  Server,
  Database,
  Cloud,
  Shield,
  Code,
  Laptop,
  Users,
  Target,
  Zap,
  Award,
  ThumbsUp,
  Briefcase,
  Box,
  Cpu,
  Settings,
  Cog,
  GitBranch,
  ArrowUpCircle,
  Shuffle,
  RefreshCw,
  FileCheck,
  Repeat,
  Workflow,
  Layers,
} from "lucide-react";

const services = [
  {
    name: "Datacenter & Operations",
    icon: Server,
    description:
      "Enterprise-grade infrastructure solutions and operational excellence",
    subServices: [
      { name: "Virtualization", icon: Box },
      { name: "HCI", icon: Database },
      { name: "Management", icon: Briefcase },
      { name: "Operations", icon: Target },
      { name: "Infrastructure Automation", icon: Zap },
    ],
  },
  {
    name: "Workload Migrations",
    icon: Laptop,
    description: "Seamless transition of workloads across platforms",
    subServices: [
      { name: "Migration Strategy", icon: Target },
      { name: "P2V", icon: ArrowUpCircle },
      { name: "V2V", icon: Shuffle },
      { name: "X2V", icon: RefreshCw },
      { name: "X2C", icon: Cloud },
    ],
  },
  {
    name: "Business Continuity & Disaster Recovery",
    icon: Shield,
    description: "Ensuring business resilience and data protection",
    subServices: [
      { name: "Local & Remote Availability", icon: Server },
      { name: "RPO, RTO, SLA", icon: Clock },
      { name: "Runbooks", icon: FileCheck },
      { name: "Failover & Failback", icon: Repeat },
      { name: "DR Automation", icon: Zap },
    ],
  },
  {
    name: "Database Modernization",
    icon: Database,
    description: "Modern database solutions for digital transformation",
    subServices: [
      { name: "Database as a Service", icon: Cloud },
      { name: "Database Migrations", icon: Shuffle },
      { name: "Database Fitchecks", icon: FileCheck },
      { name: "Database Automations", icon: Zap },
    ],
  },
  {
    name: "Cloud Automations",
    icon: Cloud,
    description: "Automated cloud solutions for enhanced efficiency",
    subServices: [
      { name: "Private Cloud", icon: Shield },
      { name: "Hybrid Cloud", icon: Cloud },
      { name: "3rd Party Integrations", icon: Workflow },
      { name: "Application Automations", icon: Zap },
      { name: "Process Automations", icon: Cog },
    ],
  },
  {
    name: "App Modernization",
    icon: Code,
    description: "Modernizing applications for the cloud era",
    subServices: [
      { name: "Containerization", icon: Box },
      { name: "Kubernetes Platform", icon: Cpu },
      { name: "CI/CD Pipelines", icon: GitBranch },
      { name: "Cloud-native Apps", icon: Cloud },
      { name: "Microservices", icon: Layers },
    ],
  },
];

const jobOpenings = [
  {
    key: "Software",
    title: "Senior Software developer – Software Development",
    department: "Product",
    location: "Hybrid/Remote",
    description:
      "AvancerPI Solutions seeks a Senior Software developer to develop scalable solution.",
  },
  {
    key: "Project",
    title: "Project Manager – Professional Services & Product Development",
    department: "Product",
    location: "Hybrid/Remote",
    description:
      "AvancerPI Solutions is hiring a Project Manager to lead projects, drive Agile development, and support business growth through client engagement and strategic planning.",
  },
  // {
  //   key: "HR",
  //   title: "HR Specialist – Team Growth and Payroll Management",
  //   department: "Product/Services",
  //   location: "Hybrid/Remote",
  //   description:
  //     "AvancerPI Solutions is seeking an HR Specialist to manage recruitment, employee engagement, and payroll, while fostering a positive workplace culture and supporting company growth.",
  // },
  {
    key: "Consultant",
    title: "Senior Consultant – HCI and Network Virtualization",
    department: "Services",
    location: "Hybrid/Remote",
    description:
      "AvancerPI Solutions is hiring a Senior Consultant specializing in HCI and Network Virtualization to design, deploy, and optimize advanced IT infrastructures, driving clients' digital transformation.",
  },
  // {
  //   key: "Migration",
  //   title: "Migration Lead – On-Premises and Cloud Migrations",
  //   department: "Services",
  //   location: "Hybrid/Remote",
  //   description:
  //     "AvancerPI Solutions is hiring a Migration Lead to oversee complex infrastructure migrations, ensuring smooth transitions and successful outcomes with minimal disruption.",
  // },
];

const coreValues = [
  {
    title: "Innovation",
    description: "Pushing boundaries in technology",
    icon: Zap,
  },
  {
    title: "Integrity",
    description: "Honesty in all our dealings",
    icon: Shield,
  },
  {
    title: "Collaboration",
    description: "Working together for success",
    icon: Users,
  },
  {
    title: "Excellence",
    description: "Striving for the highest quality",
    icon: Award,
  },
];

const latestUpdates = [
  {
    title: "New Cloud Migration Service Launched",
    description:
      "Our latest service helps businesses seamlessly transition to the cloud.",
    image: "/placeholder.svg?height=200&width=400",
  },
  {
    title: "AvancerPI Recognized as Top IT Consultant",
    description:
      "We're honored to be named one of the top IT consulting firms in the region.",
    image: "/placeholder.svg?height=200&width=400",
  },
  {
    title: "Upcoming Webinar: Future of AI in Business",
    description:
      "Join us for an insightful discussion on how AI is shaping the future of business.",
    image: "/placeholder.svg?height=200&width=400",
  },
  {
    title: "Case Study: Successful Digital Transformation",
    description:
      "Read about how we helped a major retailer completely overhaul their IT infrastructure.",
    image: "/placeholder.svg?height=200&width=400",
  },
  {
    title: "New Partnership Announcement",
    description:
      "We're excited to announce our new strategic partnership with a leading cloud provider.",
    image: "/placeholder.svg?height=200&width=400",
  },
  {
    title: "AvancerPI Expands to New Markets",
    description:
      "We're thrilled to announce our expansion into new international markets.",
    image: "/placeholder.svg?height=200&width=400",
  },
];

const teamTestimonials = [
  {
    name: "John Doe",
    title: "Senior Developer",
    quote:
      "AvancerPI has given me the opportunity to work on cutting-edge projects and grow my skills.",
    type: "text",
  },
  {
    name: "Jane Smith",
    title: "Cloud Architect",
    quote:
      "The collaborative culture here is unparalleled. We're truly pushing the boundaries of what's possible.",
    type: "text",
  },
  {
    name: "Mike Johnson",
    title: "Sales Manager",
    quote:
      "I'm proud to represent AvancerPI. Our solutions make a real difference for our clients.",
    type: "text",
  },
  {
    name: "Emily Chen",
    title: "UX Designer",
    video:
      "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Final%20Logo%20Intro%20-%20Avancer-u5hlKqo8JqWA9iCv4MxdhYidAFPsPU.mp4",
    type: "video",
  },
  {
    name: "Alex Rodriguez",
    title: "DevOps Engineer",
    quote:
      "The level of innovation and technical expertise at AvancerPI is truly inspiring.",
    type: "text",
  },
  {
    name: "Sarah Lee",
    title: "Project Manager",
    video:
      "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Final%20Logo%20Intro%20-%20Avancer-u5hlKqo8JqWA9iCv4MxdhYidAFPsPU.mp4",
    type: "video",
  },
];

export default function ContactPage() {
  const { career } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeService, setActiveService] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubjectOpen, setIsSubjectOpen] = useState(false);
  const [currentUpdateIndex, setCurrentUpdateIndex] = useState(0);
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  const [selectedSubject, setSelectedSubject] = useState("");

  const [userEmail, setUserEmail] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  const [error, setError] = useState("");

  // Function to validate the email
  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setError("Please enter a valid email address.");
    } else {
      setError("");
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    const newsData = {
      name: name,
      email: email,
      selectedSubject: selectedSubject,
      description: description,
    };

    setIsLoading(true);

    try {
      const response = await axios.post("/api/contactUs", newsData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      handleSubmit(e);

      if (response.status !== 200) {
        throw new Error(`Failed to submit form: ${response.statusText}`);
      }

      const data = response.data;
      console.log(data.message);
      setApiResponse(
        `Thank you for contacting us. You're helping us make AvancerPi better!`
      );
    } catch (error) {
      console.error("Error:", error); // Add more detailed error logging
      setApiResponse("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    setTimeout(() => setFormSubmitted(false), 3000);
  };

  const nextUpdate = () => {
    setCurrentUpdateIndex(
      (prevIndex) => (prevIndex + 1) % latestUpdates.length
    );
  };

  const prevUpdate = () => {
    setCurrentUpdateIndex(
      (prevIndex) =>
        (prevIndex - 1 + latestUpdates.length) % latestUpdates.length
    );
  };

  const nextTestimonial = () => {
    setCurrentTestimonialIndex(
      (prevIndex) => (prevIndex + 1) % teamTestimonials.length
    );
  };

  const prevTestimonial = () => {
    setCurrentTestimonialIndex(
      (prevIndex) =>
        (prevIndex - 1 + teamTestimonials.length) % teamTestimonials.length
    );
  };

  const subjectOptions = [
    { value: "general", label: "General Inquiry", color: "bg-blue-500" },
    { value: "sales", label: "Sales", color: "bg-green-500" },
    { value: "support", label: "Support", color: "bg-yellow-500" },
    { value: "job", label: "Job Application", color: "bg-purple-500" },
    { value: "other", label: "Other", color: "bg-red-500" },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <HeaderNav />

      {/* Main Content */}
      <main className="pt-20">
        {/* Contact Information Section */}
        <section className="bg-gradient-to-r from-blue-600 to-purple-600 text-white py-20">
          <div className="container mx-auto px-4">
            <h1 className="text-4xl font-bold mb-6 text-center">
              Get in Touch
            </h1>
            <p className="text-xl mb-12 text-center max-w-3xl mx-auto">
              We'd love to hear from you! Whether you're looking to transform
              your business, join our team, or learn more about our journey,
              reach out to us below.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="flex flex-col items-center">
                <Phone className="h-12 w-12 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Call Us</h3>
                <Link
                  to="https://wa.me/919902693693"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-gray-200 hover:text-white transition-colors"
                >
                  <Phone className="h-4 w-4 mr-2" />
                  +91 9902693693
                </Link>
              </div>
              <div className="flex flex-col items-center">
                <Mail className="h-12 w-12 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Email Us</h3>
                <Link
                  to="mailto:hello@avancerpi.com"
                  className="flex items-center text-gray-200 hover:text-white transition-colors"
                >
                  <Mail className="h-4 w-4 mr-2" />
                  hello@avancerpi.com
                </Link>
              </div>
              <div className="flex flex-col items-center">
                <MapPin className="h-12 w-12 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Visit Us</h3>
                <Link
                  to="https://www.google.com/maps/place/AvancerPI+Solutions(OPC)+Private+Limited/@12.9964324,77.617792,21z/data=!4m6!3m5!1s0x3bae17e837bf6e3f:0xd8bbb6a100cce9f9!8m2!3d12.9963924!4d77.6176616!16s%2Fg%2F11lw4bb2q4?authuser=0&entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-gray-200 hover:text-white transition-colors"
                >
                  <MapPin className="h-4 w-4 mr-2" />
                  AvancerPI Solutions
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Form Section */}

        <div
          onClick={() => {
            if (isSubjectOpen !== false) {
              setIsSubjectOpen(false);
            }
          }}
          className="py-20 bg-white"
        >
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">
              Send Us a Message
            </h2>
            <form onSubmit={handleUserSubmit} className="max-w-2xl mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <Input
                  type="text"
                  placeholder="Your Name"
                  required
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  type="email"
                  placeholder="Your Email"
                  value={email}
                  onChange={handleChange} // Keep both setEmail and validation
                  required
                  // className="border h-10 rounded-lg p-2"
                />
                {error && <p className="text-red-700 text-sm">{error}</p>}
              </div>
              <div className="relative mb-6">
                <div
                  className="w-full p-3 border rounded-md shadow-sm cursor-pointer flex items-center justify-between"
                  onClick={() => setIsSubjectOpen(!isSubjectOpen)}
                >
                  <span
                    className={`${
                      selectedSubject ? "text-gray-900" : "text-gray-500"
                    }`}
                  >
                    {selectedSubject
                      ? subjectOptions.find(
                          (option) => option.value === selectedSubject
                        )?.label
                      : "Select a Subject"}
                  </span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-400 transition-transform duration-300 ${
                      isSubjectOpen ? "transform rotate-180" : ""
                    }`}
                  />
                </div>
                {isSubjectOpen && (
                  <div
                    onClick={() => setIsSubjectOpen(false)}
                    className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg animate-fadeIn"
                  >
                    {subjectOptions.map((option) => (
                      <div
                        key={option.value}
                        className="flex items-center p-3 cursor-pointer hover:bg-gray-100 transition-colors duration-200"
                        onClick={() => {
                          setSelectedSubject(option.value);
                          setIsSubjectOpen(false);
                        }}
                      >
                        <div
                          className={`w-4 h-4 rounded-full mr-3 ${option.color}`}
                        ></div>
                        <span>{option.label}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <textarea
                placeholder="Your Message"
                className="mb-6 w-full p-2 border rounded-md shadow-sm"
                rows={5}
                required
                onChange={(e) => setDescription(e.target.value)}
              />
              {apiResponse && (
                <div
                  className={`mb-4 p-3 rounded-md ${
                    apiResponse.includes("error")
                      ? "bg-red-100 text-red-700"
                      : "bg-green-100 text-green-700"
                  }`}
                >
                  {apiResponse}
                </div>
              )}
              <Button
                type="submit"
                disabled={isLoading}
                className="w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Sending...
                  </>
                ) : (
                  <div className="flex justify-center items-center">
                    <div className="text-lg">Send Message</div>
                    <div>
                      <Send className="ml-2 h-4 w-4" />
                    </div>
                  </div>
                )}
              </Button>
            </form>
          </div>
        </div>

        {/* Our Culture Section */}
        <section className="py-10 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">
              Our Culture & Values
            </h2>
            <p className="text-xl mb-12 text-center max-w-3xl mx-auto">
              At AvancerPI, we foster a culture of innovation, collaboration,
              and growth. Here's what makes us a great place to work.
            </p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-16">
              {coreValues.map((value, index) => (
                <motion.div
                  key={index}
                  className="flex flex-col items-center text-center"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <value.icon className="h-12 w-12 text-blue-600 mb-4" />
                  <h3 className="text-xl font-semibold mb-2">{value.title}</h3>
                  <p className="text-gray-600">{value.description}</p>
                </motion.div>
              ))}
            </div>
            {/*
            <div className="max-w-4xl mx-auto">
              <h3 className="text-2xl font-bold mb-8 text-center">
                What Our Team Says
              </h3>
              <div className="relative">
                <div className="overflow-hidden">
                  <motion.div
                    className="flex"
                    animate={{ x: `-${currentTestimonialIndex * 100}%` }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                  >
                    {teamTestimonials.map((testimonial, index) => (
                      <div key={index} className="w-full flex-shrink-0">
                        {testimonial.type === "text" ? (
                          <div className="bg-white rounded-lg p-6 shadow-lg">
                            <p className="italic mb-4 text-gray-600">
                              "{testimonial.quote}"
                            </p>
                            <p className="font-semibold text-blue-600">
                              {testimonial.name}
                            </p>
                            <p className="text-gray-500">{testimonial.title}</p>
                          </div>
                        ) : (
                          <div className="bg-white rounded-lg p-6 shadow-lg">
                            <video
                              src={testimonial.video}
                              className="w-full mb-4 rounded"
                              controls
                            />
                            <p className="font-semibold text-blue-600">
                              {testimonial.name}
                            </p>
                            <p className="text-gray-500">{testimonial.title}</p>
                          </div>
                        )}
                      </div>
                    ))}
                  </motion.div>
                </div>
                <button
                  onClick={prevTestimonial}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg"
                  aria-label="Previous testimonial"
                >
                  <ChevronLeft className="h-6 w-6 text-blue-600" />
                </button>
                <button
                  onClick={nextTestimonial}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg"
                  aria-label="Next testimonial"
                >
                  <ChevronRight className="h-6 w-6 text-blue-600" />
                </button>
              </div>
            </div>
            */}
          </div>
        </section>

        {/* Career Section */}
        <section className="py-10 bg-gray-100" id="joinOurTeam">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">
              Join Our Team
            </h2>
            <p className="text-xl mb-12 text-center max-w-3xl mx-auto">
              We're always looking for talented, driven professionals to join us
              in our mission. Explore our current job openings and apply today!
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {jobOpenings.map((job, index) => (
                <motion.div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-6 flex flex-col justify-between h-[320px]"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <div>
                    <h3 className="text-xl font-semibold mb-2">{job.title}</h3>
                    <p className="text-gray-600 mb-4 truncate">
                      {job.department} • {job.location}
                    </p>
                    <p className="mb-4 overflow-hidden">{job.description}</p>
                  </div>
                  <Link
                    to={
                      job.title ===
                      "Senior Software developer – Software Development"
                        ? "/JobOpportunity/Software"
                        : job.title ===
                          "Project Manager – Professional Services & Product Development"
                        ? "/JobOpportunity/Project"
                        : // : job.title ===
                        //   "HR Specialist – Team Growth and Payroll Management"
                        // ? "/JobOpportunity/HR"
                        job.title ===
                          "Senior Consultant – HCI and Network Virtualization"
                        ? "/JobOpportunity/Consultant"
                        : // : job.title ===
                          //   "Migration Lead – On-Premises and Cloud Migrations"
                          // ? "/JobOpportunity/Migration"
                          ""
                    }
                  >
                    <Button className="w-full mt-auto">
                      Apply Now
                      <ArrowRight className="inline-block ml-2 h-4 w-4" />
                    </Button>
                  </Link>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
      </main>
      {/* Footer */}
      <Footer />
      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(-10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fadeIn {
          animation: fadeIn 0.3s ease-out;
        }
      `}</style>
    </div>
  );
}
