import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Server,
  Cloud,
  Briefcase,
  Users,
  ChevronRight,
  Zap,
  Globe,
  Lightbulb,
  Code,
} from "lucide-react";
import { Link } from "react-router-dom";
import HeaderNav from "./HeaderNav";
import Footer from "./Footer";
import { HashLink } from "react-router-hash-link";

const jobRoles = [
  {
    title: "Senior Consultant",
    subtitle: "HCI and Network Virtualization",
    icon: Server,
    description:
      "Lead the charge in designing and implementing cutting-edge Hyper-Converged Infrastructure and network virtualization solutions for our global clients.",
    requirements: [
      "5+ years experience in HCI",
      "Strong knowledge of network virtualization",
      "Excellent communication skills",
    ],
  },
  // {
  //   title: "Migration Lead",
  //   subtitle: "On-Prem and Cloud Transformations",
  //   icon: Cloud,
  //   description:
  //     "Guide our clients through seamless transitions from on-premises infrastructure to cloud environments, ensuring minimal disruption and maximum efficiency.",
  //   requirements: [
  //     "Proven track record in cloud migrations",
  //     "Deep understanding of both on-prem and cloud architectures",
  //     "Strong project management skills",
  //   ],
  // },
  {
    title: "Project Manager",
    subtitle: "Driving Professional Services & Innovation",
    icon: Briefcase,
    description:
      "Spearhead complex IT transformation projects, ensuring timely delivery, client satisfaction, and innovative solutions that drive business value.",
    requirements: [
      "PMP certification",
      "7+ years in IT project management",
      "Experience with Agile methodologies",
    ],
  },
  // {
  //   title: "HR Specialist",
  //   subtitle: "Empowering Our Growing Team",
  //   icon: Users,
  //   description:
  //     "Play a crucial role in building and nurturing our world-class team, focusing on talent acquisition, employee development, and fostering a vibrant company culture.",
  //   requirements: [
  //     "3+ years in HR, preferably in tech",
  //     "Experience with modern HR tools and practices",
  //     "Strong interpersonal skills",
  //   ],
  // },
  {
    title: "Senior Software Engineer",
    subtitle:
      "Develop scalable solutions in virtualization, HCI, cloud, and modernization",
    icon: Code,
    description:
      "AvancerPI Solutions seeks a Senior Software Engineer to develop scalable solutions in virtualization, HCI, cloud, and modernization.",
    requirements: [
      "Bachelor’s or Master’s degree in Computer Science, Software Engineering, or a related field.",
      "5+ years of experience in software development.",
      "Familiarity with tools like Swagger or Postman for API development and testing.",
    ],
  },
];

export default function HiringPage() {
  const [selectedJob, setSelectedJob] = useState(null);

  return (
    <>
      <HeaderNav />
      <div className="min-h-screen bg-gradient-to-br from-indigo-900 via-purple-800 to-pink-700">
        <div className="container mx-auto px-4 py-16 ">
          <motion.h1
            className="text-5xl md:text-7xl font-bold text-center text-white mb-8"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            We&apos;re Hiring!
          </motion.h1>
          <motion.p
            className="text-xl md:text-2xl text-center text-purple-200 mb-16"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Join our team of innovators and shape the future of IT
          </motion.p>

          <div className="flex justify-center">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
              {jobRoles.map((job, index) => (
                <motion.div
                  key={job.title}
                  className="bg-white bg-opacity-10 rounded-lg p-6 cursor-pointer hover:bg-opacity-20 transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  onClick={() => setSelectedJob(job)}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <job.icon className="w-12 h-12 text-purple-300 mb-4" />
                  <h2 className="text-2xl font-bold text-white mb-2">
                    {job.title}
                  </h2>
                  <p className="text-purple-200">{job.subtitle}</p>
                </motion.div>
              ))}
            </div>
          </div>

          <AnimatePresence>
            {selectedJob && (
              <motion.div
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <motion.div
                  className="bg-white rounded-lg p-8 max-w-2xl w-full"
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.9, opacity: 0 }}
                >
                  <h2 className="text-3xl font-bold mb-4 text-indigo-900">
                    {selectedJob.title}
                  </h2>
                  <p className="text-xl text-purple-700 mb-4">
                    {selectedJob.subtitle}
                  </p>
                  <p className="text-gray-700 mb-6">
                    {selectedJob.description}
                  </p>
                  <h3 className="text-xl font-semibold mb-2 text-indigo-900">
                    Key Requirements:
                  </h3>
                  <ul className="list-disc pl-5 mb-6 text-gray-700">
                    {selectedJob.requirements.map((req, index) => (
                      <li key={index}>{req}</li>
                    ))}
                  </ul>
                  <div className="flex justify-between">
                    <button
                      className="bg-purple-600 text-white px-6 py-2 rounded-full hover:bg-purple-700 transition-colors duration-300"
                      onClick={() => setSelectedJob(null)}
                    >
                      Close
                    </button>
                    <Link
                      to={
                        selectedJob.title === "Project Manager"
                          ? "/JobOpportunity/Project"
                          : selectedJob.title === "HR Specialist"
                          ? "/JobOpportunity/HR"
                          : selectedJob.title === "Senior Consultant"
                          ? "/JobOpportunity/Consultant"
                          : selectedJob.title === "Migration Lead"
                          ? "/JobOpportunity/Migration"
                          : selectedJob.title === "Senior Software Engineer"
                          ? "/JobOpportunity/Software"
                          : ""
                      }
                      className="bg-indigo-600 text-white px-6 py-2 rounded-full hover:bg-indigo-700 transition-colors duration-300 flex items-center"
                    >
                      Apply Now
                      <ChevronRight className="ml-2 h-5 w-5" />
                    </Link>
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>

          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-8">
              Why Join AvancerPI?
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              <motion.div
                className="bg-white bg-opacity-10 rounded-lg p-6"
                whileHover={{ scale: 1.05 }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Zap className="w-12 h-12 text-yellow-400 mx-auto mb-4" />
                <h3 className="text-xl font-bold text-white mb-2">
                  Cutting-edge Tech
                </h3>
                <p className="text-purple-200">
                  Work with the latest technologies and shape the future of IT
                </p>
              </motion.div>
              <motion.div
                className="bg-white bg-opacity-10 rounded-lg p-6"
                whileHover={{ scale: 1.05 }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <Globe className="w-12 h-12 text-green-400 mx-auto mb-4" />
                <h3 className="text-xl font-bold text-white mb-2">
                  Global Impact
                </h3>
                <p className="text-purple-200">
                  Make a difference in organizations worldwide
                </p>
              </motion.div>
              <motion.div
                className="bg-white bg-opacity-10 rounded-lg p-6"
                whileHover={{ scale: 1.05 }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <Lightbulb className="w-12 h-12 text-orange-400 mx-auto mb-4" />
                <h3 className="text-xl font-bold text-white mb-2">
                  Continuous Learning
                </h3>
                <p className="text-purple-200">
                  Grow your skills with our comprehensive training programs
                </p>
              </motion.div>
            </div>
          </div>

          <div className="text-center">
            <HashLink to="/ContactUs/career#joinOurTeam">
              <div className="inline-block bg-white text-indigo-900 px-8 py-3 rounded-full text-xl font-bold hover:bg-purple-100 transition-colors duration-300">
                Explore All Opportunities
              </div>
            </HashLink>
          </div>
        </div>

        {/* Animated background */}
        <div className="fixed inset-0 pointer-events-none">
          {[...Array(20)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute bg-white rounded-full opacity-20"
              style={{
                width: Math.random() * 20 + 10,
                height: Math.random() * 20 + 10,
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
              }}
              animate={{
                y: [0, -100],
                opacity: [0.2, 0],
              }}
              transition={{
                duration: Math.random() * 10 + 10,
                repeat: Infinity,
                repeatType: "loop",
              }}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
