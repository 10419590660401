import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import About from "./screens/AboutUs";
import Services from "./screens/Services";
import ContactUs from "./screens/ContactUs";
import DataCenterOperationsPage from "./screens/SubServices/DataCenterOperationsPage";
import PageNotFound from "./screens/PageNotFound";
import JobOpportunity from "./screens/JobOpportunityPage";
import SubServicesModal from "./screens/SubServices/SubServicesModal";
import CustomerSuccessStory from "./screens/SuccessStory/CustomerSuccessStory"
import SaudiLeadingBankSuccess from "./screens/SuccessStory/SaudiLeadingBankSuccess"
import SaudiDigitalLeaderSuccessStory from "./screens/SuccessStory/SaudiDigitalLeaderSuccessStory"
import GlobalTelecomSolutionsProviderSuccessStory from "./screens/SuccessStory/GlobalTelecomSolutionsProviderSuccessStory"
import GlobalEngineeringPowerhouseSuccessStory from "./screens/SuccessStory/GlobalEngineeringPowerhouseSuccessStory"
import IndianCommunicationInnovatorSuccessStory from "./screens/SuccessStory/IndianCommunicationInnovatorSuccessStory"
import AllApplocation from './screens/AllApplication'
import Hiring from "./screens/HiringPage"
import LoginPage from './Login'
import ProtectedRoute from "./ProtectedRoutes";
import AdminDashboard from "./screens/JobsDash/AdminDashboard"
import AdminLogin from "./screens/JobsDash/AdminLogin"

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Home/:ServiceNames" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />}>
            <Route
              path="/Services/:ServiceNames"
              element={<Services />}
            />
          </Route>
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/ContactUs/:career" element={<ContactUs />} />
          <Route path="/SubServicesModal" element={<SubServicesModal />} />
          <Route path="/CustomerSuccessStory" element={<CustomerSuccessStory />} />
          {/* <Route path="/CustomerSuccessStory/:SuccessStory" element={<CustomerSuccessStory />} /> */}
          <Route path="/SaudiLeadingBankSuccess" element={<SaudiLeadingBankSuccess />} />

          <Route path="/AdminLogin" element={<AdminLogin />} />

          <Route path="/SaudiDigitalLeaderSuccessStory" element={<SaudiDigitalLeaderSuccessStory />} />
          <Route path="/GlobalTelecomSolutionsProviderSuccessStory" element={<GlobalTelecomSolutionsProviderSuccessStory />} />
          <Route path="/GlobalEngineeringPowerhouseSuccessStory" element={<GlobalEngineeringPowerhouseSuccessStory />} />
          <Route path="/IndianCommunicationInnovatorSuccessStory" element={<IndianCommunicationInnovatorSuccessStory />} />


          {/* <Route path="/AllApplications" element={<AllApplocation />} /> */}
          <Route path="/Hiring" element={<Hiring />} />
          <Route
            path="/SubServicesModal/:subserviceName"
            element={<SubServicesModal />}
          />
          <Route path="/JobOpportunity" element={<JobOpportunity />} />
          <Route
            path="/JobOpportunity/:jobName"
            element={<JobOpportunity />}
          />
          <Route
            path="/DataCenterOperationsPage"
            element={<DataCenterOperationsPage />}
          />
          <Route path="*" element={<PageNotFound />} />


          {/* protected routes down here ------------------ */}
          <Route
            path="/AllApplications"
            element={
              <ProtectedRoute>
                <AllApplocation />
              </ProtectedRoute>
            }
          />

          <Route
            path="/AdminDashboard"
            // element={<AdminDashboard />} 
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
