import React, { useEffect, useRef } from "react";
import {
  motion,
  useAnimation,
  useInView,
  useScroll,
  useTransform,
} from "framer-motion";
import {
  Zap,
  Server,
  Shield,
  Cloud,
  CheckCircle,
  ChevronRight,
  Network,
  Users,
  Database,
} from "lucide-react";
import { Link } from "react-router-dom";
import Global_engineering_powrhose from "../../assets/SuccessStories/Global_engineering_powrhose.jpg";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import HeaderNav from "../HeaderNav";
import Footer from "../Footer";
const ParallaxSection = ({ children, className = "" }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const y = useTransform(scrollYProgress, [0, 1], [50, -50]);

  return (
    <motion.div ref={ref} style={{ y }} className={className}>
      {children}
    </motion.div>
  );
};

const AnimatedCounter = ({ target, duration = 2, suffix = "" }) => {
  const nodeRef = useRef(null);
  const controls = useAnimation();
  const inView = useInView(nodeRef);

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.5 },
      });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={nodeRef}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={controls}
    >
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: duration, ease: "easeOut" }}
      >
        {target}
      </motion.span>
      <span>{suffix}</span>
    </motion.div>
  );
};

const GlowingCard = ({ children, className = "" }) => (
  <motion.div
    className={`relative group ${className}`}
    whileHover={{ scale: 1.02 }}
    transition={{ duration: 0.3 }}
  >
    <motion.div
      className="absolute inset-0 bg-gradient-to-r from-cyan-500/20 to-blue-500/20 rounded-lg blur-xl group-hover:opacity-75 transition-opacity duration-500 opacity-0"
      animate={{
        scale: [1, 1.1, 1],
        opacity: [0.5, 0.7, 0.5],
      }}
      transition={{
        duration: 4,
        repeat: Infinity,
        ease: "linear",
      }}
    />
    {children}
  </motion.div>
);

const GlobalEngineeringPowerhouseSuccessStory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeaderNav />
      <Box sx={{ minHeight: "100vh", bgcolor: "background.paper" }}>
        {/* Hero Section */}
        <div className="relative flex items-end justify-center h-[500px] md:min-h-screen overflow-hidden">
          {/* Background Image */}
          <img
            src={Global_engineering_powrhose}
            alt="Global Engineering Infrastructure Transformation"
            className="absolute top-0 left-0 w-full h-full object-cover z-10"
          />
          {/* Overlay Animation */}
          <motion.div
            className="absolute inset-0 z-0"
            animate={{
              backgroundColor: [
                "rgba(0,0,0,0.3)",
                "rgba(0,0,0,0.5)",
                "rgba(0,0,0,0.3)",
              ],
            }}
            transition={{
              duration: 5,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          {/* Content */}
          <div className="flex mb-6 flex-col p-6 rounded-lg bg-black bg-opacity-50 mx-6 items-end relative z-20 text-white text-center px-6 md:px-0">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.2 }}
              className="flex flex-col justify-center items-end"
            >
              <h1 className="text-4xl md:text-6xl font-bold mb-4">
                Transforming Efficiency for a Leading Global Engineering
                Powerhouse
              </h1>
              <p className="text-lg md:text-2xl">
                Empowering a global leader with cutting-edge HCI and Kubernetes
                solutions for unparalleled performance and scalability
              </p>
            </motion.div>
          </div>
        </div>

        {/* Background and Challenge Section */}
        <motion.section
          className="py-20 bg-gray-50"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <div className="container mx-auto px-4">
            <ParallaxSection className="mb-12">
              <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-900">
                Background and Challenges
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-2 gap-8">
              {/* Background Card */}
              <GlowingCard className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
                  Background
                </h3>
                <p className="text-base md:text-lg text-gray-600">
                  A global engineering leader faced performance issues and
                  inefficient resource utilization on their HyperV platform.
                  They required a robust, scalable solution to optimize their
                  data center operations, improve resource efficiency, and adopt
                  modern infrastructure practices like HCI and Kubernetes.
                </p>
              </GlowingCard>
              <GlowingCard className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
                  Challenges
                </h3>
                <ul className="space-y-4">
                  {[
                    {
                      icon: <Server className="text-blue-500" />,
                      text: "Performance issues and inefficient resource utilization on HyperV platform",
                    },
                    {
                      icon: <Database className="text-blue-500" />,
                      text: "Need for optimized data center operations across multiple locations",
                    },
                    {
                      icon: <Cloud className="text-blue-500" />,
                      text: "Adoption of modern infrastructure practices like HCI and Kubernetes",
                    },
                    {
                      icon: <Users className="text-blue-500" />,
                      text: "Upskilling IT staff to manage new technologies efficiently",
                    },
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <span className="mr-3">{item.icon}</span>
                      <p className="text-gray-600">{item.text}</p>
                    </li>
                  ))}
                </ul>
              </GlowingCard>
            </div>
          </div>
        </motion.section>

        {/* Solution Section */}
        <motion.section
          className="py-20 bg-black text-white relative overflow-hidden"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <motion.div
            className="absolute inset-0 opacity-20"
            animate={{
              background: [
                "radial-gradient(circle at 30% 30%, #22D3EE 0%, transparent 60%)",
                "radial-gradient(circle at 70% 70%, #22D3EE 0%, transparent 60%)",
                "radial-gradient(circle at 30% 30%, #22D3EE 0%, transparent 60%)",
              ],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <div className="container mx-auto px-4 relative z-10">
            <ParallaxSection>
              <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
                Our Solution
              </h2>
            </ParallaxSection>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  title: "Infrastructure Deployment",
                  description:
                    "Deployed Nutanix AHV across three data centers with Metro Availability for resilience.",
                  icon: Server,
                },
                {
                  title: "Security Enhancement",
                  description:
                    "Implemented Flow Micro-Segmentation for advanced network security.",
                  icon: Shield,
                },
                {
                  title: "Kubernetes Integration",
                  description:
                    "Set up Karbon Kubernetes Clusters and containerized applications for modern workloads.",
                  icon: Cloud,
                },
                {
                  title: "Seamless Migration",
                  description:
                    "Utilized Nutanix Move for smooth transitions from HyperV to AHV across various OS environments.",
                  icon: Network,
                },
                {
                  title: "Team Enablement",
                  description:
                    "Mentored IT staff on managing HCI and Kubernetes, fostering self-sufficiency.",
                  icon: Users,
                },
              ].map((item, index) => (
                <GlowingCard
                  key={index}
                  className="bg-white/10 backdrop-blur-sm p-6 rounded-lg"
                >
                  <item.icon className="w-12 h-12 mb-4 text-cyan-400" />
                  <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                  <p className="text-gray-300">{item.description}</p>
                </GlowingCard>
              ))}
            </div>
          </div>
        </motion.section>

        {/* Impact Section */}
        <Box sx={{ py: 10, bgcolor: "background.paper" }}>
          <Container maxWidth="lg">
            <ParallaxSection>
              <Typography variant="h3" align="center" gutterBottom>
                Business Benefits
              </Typography>
            </ParallaxSection>
            <Grid container spacing={4} sx={{ mb: 6 }}>
              {[
                {
                  value: 100,
                  label: "Migration Success",
                  icon: CheckCircle,
                  suffix: "%",
                },
                { value: 3, label: "Data Centers Optimized", icon: Database },
                {
                  value: 100,
                  label: "Team Enablement",
                  icon: Users,
                  suffix: "%",
                },
                { value: 2, label: "New Tech: HCI & K8s", icon: Cloud },
              ].map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <GlowingCard className="text-center bg-gray-50 p-6 rounded-lg shadow-lg">
                    <CardContent sx={{ textAlign: "center" }}>
                      <item.icon className="w-12 h-12 mx-auto mb-4 text-cyan-600" />
                      <Typography variant="h4" component="div" gutterBottom>
                        <AnimatedCounter
                          target={item.value}
                          suffix={item.suffix}
                        />
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.label}
                      </Typography>
                    </CardContent>
                  </GlowingCard>
                </Grid>
              ))}
            </Grid>
          </Container>
          <motion.div
            className="text-base md:text-lg text-center max-w-3xl mx-auto text-gray-600"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <h3 className="text-xl font-semibold mb-4 text-gray-900">
              Key Achievements:
            </h3>
            <ul className="list-disc list-inside space-y-2 text-left">
              <li>
                Improved Performance: Eliminated resource inefficiencies,
                boosting overall infrastructure efficiency.{" "}
              </li>
              <li>
                Modernized Infrastructure: Adopted HCI and Kubernetes for
                scalable, future-ready operations.{" "}
              </li>
              <li>
                Enhanced Resilience: Achieved robust data center availability
                with Metro setup and micro-segmentation.{" "}
              </li>
              <li>
                Upskilled Team: Empowered the IT team to independently manage
                Nutanix platforms and Kubernetes clusters.{" "}
              </li>
            </ul>
          </motion.div>
        </Box>

        {/* Call to Action Section */}
        <Box
          sx={{
            py: 10,
            bgcolor: "black",
            color: "white",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <motion.div
            style={{
              position: "absolute",
              inset: 0,
              opacity: 0.2,
            }}
            animate={{
              background: [
                "radial-gradient(circle at 0% 0%, #22D3EE 0%, transparent 50%)",
                "radial-gradient(circle at 100% 100%, #22D3EE 0%, transparent 50%)",
                "radial-gradient(circle at 0% 0%, #22D3EE 0%, transparent 50%)",
              ],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "linear",
            }}
          />
          <Container
            maxWidth="lg"
            sx={{ textAlign: "center", position: "relative", zIndex: 1 }}
          >
            <Typography variant="h3" gutterBottom>
              Learn how we can help modernize your infrastructure
            </Typography>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Link to="/ContactUs">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<ChevronRight />}
                  sx={{
                    mt: 2,
                    py: 1.5,
                    px: 4,
                    borderRadius: "9999px",
                    "&:hover": {
                      transform: "scale(1.05)",
                      transition: "transform 0.3s",
                    },
                  }}
                >
                  Contact Our Experts
                </Button>
              </Link>
            </motion.div>
          </Container>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default GlobalEngineeringPowerhouseSuccessStory;
