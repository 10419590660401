"use client";

import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Briefcase, ChevronLeft, Upload, Check } from "lucide-react";
import Button from "../components/button";
import { toast, Toaster } from "react-hot-toast";
import ContactUs from "./ContactUs";
import { Link } from "react-router-dom";
import { Modal, Box, Typography, TextField, IconButton } from "@mui/material";
import { Upload as UploadIcon, Close as CloseIcon } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { CircularProgress } from "@mui/material";
import axios from "axios";

const jobDetails = [
  {
    key: "Software",
    title: "Senior Software developer – Software Development",
    department: "Product",
    location: "Banglore",
    type: "Hybrid/Remote",
    description:
      "AvancerPI Solutions is seeking an experienced Senior Software developer to join our innovative team. At AvancerPI Solutions, we are a globally recognized team of Consulting Architects, Developers, and Automation Engineers dedicated to delivering enterprise-grade IT solutions. Our projects include Datacenter Virtualization, HCI Solutions, Workload Migrations, Hybrid Cloud Integrations, and Application Modernization . his role will involve designing and developing cutting-edge software solutions that reflect our commitment to innovation, scalability, and technical excellence. If you’re passionate about creating robust applications and thrive in a consultative, collaborative environment, we’d love to hear from you.",
    responsibilities: [
      {
        title: "Software Architecture & Development",
        points: [
          "Design and develop modular, scalable software solutions in alignment with AvancerPI Solutions' focus on delivering high-quality, enterprise-grade tools.",
          "Collaborate with stakeholders to ensure solutions meet client requirements and business goals.",
          "Maintain code quality through rigorous testing and adherence to best practices.",
        ],
      },
      {
        title: "Backend Development",
        points: [
          "Build robust, efficient, and secure backend systems using Python, Go, Java, or JavaScript (Node.js).",
          "Develop and optimize RESTful and GraphQL APIs for seamless integration.",
        ],
      },
      {
        title: "Frontend Development",
        points: [
          "Create responsive, dynamic, and intuitive UIs using frameworks like React, Angular, or Vue.js.",
          "Ensure user experiences align with AvancerPI Solutions' emphasis on simplicity and usability.",
        ],
      },
      {
        title: "Database Design & Management",
        points: [
          "Develop and maintain scalable database solutions using PostgreSQL, MySQL, MongoDB, or Redis.",
          "Optimize data models to support AvancerPI Solutions' high-performance applications.",
        ],
      },
      {
        title: "Testing & Quality Assurance",
        points: [
          "Implement comprehensive testing pipelines using tools like Jest, Mocha, or PyTest.",
          "Deliver reliable software aligned with AvancerPI Solutions' design principles of availability, security, and manageability.",
        ],
      },
      {
        title: "Version Control & Collaboration",
        points: [
          "Collaborate with the team using Git and platforms like GitHub or GitLab.",
          "Participate in Agile development workflows with tools like JIRA or Trello.",
        ],
      },
    ],
    requirements: [
      {
        title: "Programming Languages & Frameworks",
        points: [
          "Backend: Python (Django, Flask), Go, Java (Spring Boot), Node.js (Express).",
          "Frontend: React, Angular, Vue.js, or Svelte.",
        ],
      },
      {
        title: "Database Technologies",
        points: [
          "SQL: PostgreSQL, MySQL, SQLite.",
          "NoSQL: MongoDB, Redis, Cassandra.",
        ],
      },
      {
        title: "Testing Frameworks",
        points: ["Jest, Mocha, Cypress, Selenium, PyTest."],
      },
      {
        title: "DevOps-Friendly Development",
        points: [
          "Familiarity with containerized environments like Docker.",
          "Knowledge of orchestration tools such as Kubernetes.",
        ],
      },
      {
        title: "Version Control & Collaboration",
        points: ["Git, GitHub, Bitbucket, GitLab."],
      },
    ],
    Qualifications: [
      "Bachelor’s or Master’s degree in Computer Science, Software Engineering, or a related field.",
      "5+ years of experience in software development.",
      "Familiarity with tools like Swagger or Postman for API development and testing.",
    ],
    About: [
      "At AvancerPI Solutions, we pride ourselves on being trusted advisors, delivering IT transformations with precision and innovation. Our focus is on achieving high availability, security, manageability, scalability, and simplicity in every solution we design. We approach every project with a consultative mindset, ensuring our software meets both technical and business requirements.",
    ],
  },
  {
    key: "Project",
    title: "Project Manager – Professional Services & Product Development",
    department: "Product",
    location: "Banglore",
    type: "Hybrid/Remote",
    description:
      "AvancerPI Solutions is seeking a dynamic and skilled Project Manager to drive the successful delivery of professional services projects, facilitate Agile product development, and support business growth through client engagement and strategic planning. This role requires a balance of project management expertise, the ability to foster collaboration within development teams, and a consultative mindset to identify and address client needs effectively.",
    responsibilities: [
      {
        title: "Professional Services Project Management",
        points: [
          "Plan, execute, and oversee multiple professional services projects, ensuring timely delivery and alignment with client objectives.",
          "Monitor project progress, manage risks, and implement corrective actions when necessary.",
          "Collaborate with internal teams and clients to define project scope, deliverables, and success metrics.",
          "Create detailed project documentation, including schedules, budgets, and status reports.",
        ],
      },
      {
        title: "Scrum Master for Product Development",
        points: [
          "Facilitate Agile ceremonies, including daily stand-ups, sprint planning, reviews, and retrospectives.",
          "Remove roadblocks for the development team, ensuring smooth workflow and collaboration.",
          "Coach team members on Agile principles and practices to enhance productivity and delivery.",
          "Work closely with Product Owners and stakeholders to prioritize backlogs and deliver features that align with business goals.",
        ],
      },
      {
        title: "Services Sales Manager Role",
        points: [
          "Identify and pursue new professional services opportunities, leveraging AvancerPI Solutions’ expertise in consulting, migrations, cloud, and app modernization.",
          "Prepare proposals, conduct presentations, and negotiate contracts with clients.",
          "Build and maintain strong client relationships to foster repeat business and referrals.",
          "Collaborate with technical teams to align service offerings with client needs and market trends.",
        ],
      },
      {
        title: "Cross-Functional Leadership",
        points: [
          "Act as the central point of communication between clients, internal teams, and stakeholders.",
          "Balance competing priorities across professional services, product development, and sales efforts.",
          "Provide strategic insights to drive continuous improvement in processes and deliverables.",
        ],
      },
    ],
    requirements: [
      {
        title: "Project Management",
        points: [
          "5+ years of experience in managing professional services projects in IT, cloud, or app modernization.",
          "Proficiency in project management tools like MS Project, Smartsheet, JIRA, or Asana.",
          "Strong understanding of project lifecycle methodologies, including Waterfall and Agile.",
        ],
      },
      {
        title: "Agile/Scrum Leadership",
        points: [
          "Certified Scrum Master (CSM) or equivalent certification preferred.",
          "Hands-on experience in facilitating Agile development for software products.",
          "Expertise in backlog management, sprint planning, and fostering team collaboration.",
        ],
      },
      {
        title: "Sales & Business Development",
        points: [
          "Proven experience in professional services sales, including preparing proposals and negotiating contracts.",
          "Strong consultative sales skills with the ability to identify client pain points and position solutions.",
          "Familiarity with CRM tools like Salesforce, HubSpot, or similar platforms.",
        ],
      },
      {
        title: "Soft Skills",
        points: [
          "Exceptional communication and negotiation skills.",
          "Ability to juggle multiple responsibilities with a focus on delivering value.",
          "A proactive and solution-oriented mindset.",
        ],
      },
    ],
    Qualifications: [
      "Bachelor’s or Master’s degree in Business Administration, IT Management, or a related field.",
      "PMP or PRINCE2 certification.",
      "Strong technical understanding of cloud solutions, migrations, and app modernization.",
    ],
    About: [
      "At AvancerPI Solutions, we pride ourselves on being trusted advisors, delivering IT transformations with precision and innovation. Our focus is on achieving high availability, security, manageability, scalability, and simplicity in every solution we design. We approach every project with a consultative mindset, ensuring our software meets both technical and business requirements.",
    ],
  },
  {
    key: "HR",
    title: "HR Specialist – Team Growth and Payroll Management",
    department: "Product/Services",
    location: "Banglore",
    type: "Hybrid/Remote",
    description:
      "AvancerPI Solutions is seeking an experienced and proactive HR Specialist to join our team. This role is pivotal in supporting our company’s growth by managing all aspects of human resources, from recruitment to employee engagement, while ensuring seamless payroll processing. The ideal candidate is passionate about building a positive workplace culture, excels in organizational skills, and thrives in a fast-paced environment.",
    responsibilities: [
      {
        title: "Talent Acquisition and Recruitment",
        points: [
          "Design and implement recruitment strategies to attract top talent for technical, managerial, and creative roles.",
          "Manage end-to-end hiring processes, including job postings, interviews, and onboarding.",
          "Build and maintain a pipeline of potential candidates to meet future staffing needs.",
        ],
      },
      {
        title: "Employee Engagement and Development",
        points: [
          "Develop and execute initiatives to enhance employee satisfaction, retention, and engagement.",
          "Act as the point of contact for employees, addressing queries and concerns promptly.",
          "Organize team-building activities, training sessions, and performance reviews.",
        ],
      },
      {
        title: "HR Policy and Compliance",
        points: [
          "Ensure adherence to company policies, labor laws, and industry regulations.",
          "Update and maintain employee handbooks, contracts, and HR documentation.",
          "Handle conflict resolution and employee grievances with discretion and professionalism.",
        ],
      },
      {
        title: "Payroll and Benefits Administration",
        points: [
          "Process monthly payroll accurately, ensuring compliance with tax regulations and benefits programs.",
          "Manage employee benefits, including health insurance, retirement plans, and other perks.",
          "Maintain detailed records of payroll data, tax filings, and compliance reports.",
        ],
      },
      {
        title: "Workforce Planning and Growth",
        points: [
          "Collaborate with leadership to align HR strategies with organizational goals.",
          "Identify and address workforce gaps, planning for future growth needs.",
          "Provide insights and data on workforce metrics to inform decision-making.",
        ],
      },
    ],
    requirements: [
      {
        title: "HR Expertise",
        points: [
          "Proven experience in HR management, including recruitment, onboarding, and employee relations.",
          "Knowledge of HR policies, labor laws, and compliance requirements.",
        ],
      },
      {
        title: "Payroll Proficiency",
        points: [
          "Experience in payroll processing using tools like GreyHR, Kredily, or equivalent systems.",
          "Strong understanding of tax regulations, benefits administration, and reporting.",
        ],
      },
      {
        title: "Communication and Interpersonal Skills",
        points: [
          "Ability to build strong relationships with employees and leadership.",
          "Excellent verbal and written communication skills.",
        ],
      },
      {
        title: "Organizational Skills",
        points: [
          "Ability to manage multiple HR functions simultaneously in a fast-paced environment.",
          "Attention to detail and problem-solving abilities.",
        ],
      },
    ],
    Qualifications: [
      "Bachelor’s or Master’s degree in Human Resources, Business Administration, or a related field.",
      "Certification in HR management (e.g., SHRM-CP, PHR).",
      "3+ years of experience in HR and payroll management.",
    ],
    About: [
      "At AvancerPI Solutions, we pride ourselves on being trusted advisors, delivering IT transformations with precision and innovation. Our focus is on achieving high availability, security, manageability, scalability, and simplicity in every solution we design. We approach every project with a consultative mindset, ensuring our software meets both technical and business requirements.",
    ],
  },
  {
    key: "Consultant",
    title: "Senior Consultant – HCI and Network Virtualization",
    department: "Services",
    location: "Banglore",
    type: "Hybrid/Remote",
    description:
      "AvancerPI Solutions is seeking a highly skilled Senior Consultant specializing in HCI (Hyper-Converged Infrastructure) and Network Virtualization to join our Professional Services team. In this role, you will collaborate with clients to design, deploy, and optimize advanced IT infrastructures, ensuring seamless integration and high performance across their data centers. Your expertise will be pivotal in guiding clients through their digital transformation journey, leveraging modern infrastructure technologies and network virtualization solutions.",
    responsibilities: [
      {
        title: "Client Engagement",
        points: [
          "Lead workshops and consulting sessions to understand client requirements and establish architectural vision and direction.",
        ],
      },
      {
        title: "Solution Design",
        points: [
          "Develop comprehensive design documents and scalable solutions tailored to client needs in HCI and Network Virtualization.",
        ],
      },
      {
        title: "Implementation",
        points: [
          "Perform hands-on deployment and migration of complex infrastructures, ensuring optimal reliability and performance.",
        ],
      },
      {
        title: "Technical Governance",
        points: [
          "Oversee successful outcomes of infrastructure and network projects through requirements capture and technical governance.",
        ],
      },
      {
        title: "Collaboration",
        points: [
          "Work closely with software developers, consultants, and engineers to create integrated solutions for clients.",
        ],
      },
      {
        title: "Pre-Sales Support",
        points: [
          "Provide technical input during the pre-sales stage, including effort estimation and technical reviews.",
        ],
      },
      {
        title: "Practice Development",
        points: [
          "Contribute to the development of best practices, intellectual property, and peer reviews within the team.",
        ],
      },
    ],
    requirements: [
      {
        title: "HCI Expertise",
        points: [
          "Strong experience with hyper-converged infrastructure solutions, such as Nutanix, VMware vSAN, or similar platforms.",
          "Understanding of hardware and software integration in HCI environments.",
        ],
      },
      {
        title: "Network Virtualization Skills",
        points: [
          "Hands-on experience with network virtualization technologies, such as VMware NSX, Nutanix Flow, or Cisco ACI.",
          "Deep knowledge of SDN (Software-Defined Networking) principles.",
        ],
      },
      {
        title: "Cloud and Automation Skills",
        points: [
          "Proficiency in hybrid and private cloud architectures.",
          "Experience with automation tools like Ansible, Terraform, or PowerShell.",
        ],
      },
      {
        title: "Scripting Knowledge",
        points: [
          "Proficiency in scripting languages, such as Python, Bash, or PowerShell.",
        ],
      },
      {
        title: "DevOps and CI/CD",
        points: [
          "Familiarity with DevOps tools and CI/CD pipelines, such as Jenkins and Git.",
        ],
      },
      {
        title: "Communication and Leadership",
        points: [
          "Ability to lead projects, collaborate with cross-functional teams, and provide technical mentorship.",
        ],
      },
    ],
    Qualifications: [
      "Bachelor’s or Master’s degree in Computer Science, Engineering, or a related field.",
      "Certifications in HCI (e.g., Nutanix NCM, VMware VCAP) and Network Virtualization (e.g., VMware NSX, Cisco certifications).",
    ],
    About: [
      "At AvancerPI Solutions, we pride ourselves on being trusted advisors, delivering IT transformations with precision and innovation. Our focus is on achieving high availability, security, manageability, scalability, and simplicity in every solution we design. We approach every project with a consultative mindset, ensuring our software meets both technical and business requirements.",
    ],
  },
  {
    key: "Migration",
    title: "Migration Lead – On-Premises and Cloud Migrations",
    department: "Services",
    location: "Banglore",
    type: "Hybrid/Remote",
    description:
      "AvancerPI Solutions is seeking a highly skilled Migration Lead to oversee and execute complex infrastructure migrations, including on-premises to on-premises transitions and on-premises to cloud transformations. This role requires a deep understanding of modern infrastructure technologies, strong project leadership skills, and the ability to ensure seamless migrations with minimal disruption to clients' operations. As a Migration Lead, you will collaborate closely with clients and internal teams to design migration strategies, execute implementation plans, and ensure successful outcomes that meet both technical and business requirements.",
    responsibilities: [
      {
        title: "Project Leadership",
        points: [
          "Lead end-to-end migration projects, ensuring alignment with client objectives and technical requirements.",
          "Develop migration roadmaps and plans, identifying and mitigating risks to achieve seamless transitions.",
          "Manage project timelines, resources, and deliverables effectively.",
        ],
      },
      {
        title: "Technical Expertise",
        points: [
          "Assess current IT environments and design migration strategies tailored to specific infrastructures.",
          "Execute migrations involving hyper-converged infrastructures, network virtualization, and hybrid cloud environments.",
          "Leverage automation and scripting tools to optimize migration processes.",
        ],
      },
      {
        title: "Stakeholder Collaboration",
        points: [
          "Act as the primary liaison between clients and internal teams, ensuring clear communication and successful outcomes.",
          "Provide regular project updates, addressing technical and logistical challenges as they arise.",
        ],
      },
      {
        title: "Risk Management",
        points: [
          "Identify potential risks and develop mitigation strategies to ensure migration success.",
          "Ensure adherence to industry best practices and compliance standards throughout the migration process.",
        ],
      },
      {
        title: "Documentation and Support",
        points: [
          "Create detailed documentation of migration plans, configurations, and processes.",
          "Provide post-migration support, training, and knowledge transfer to clients and internal teams.",
        ],
      },
    ],
    requirements: [
      {
        title: "Technical Proficiency",
        points: [
          "Strong experience with hyper-converged infrastructure (e.g., Nutanix, VMware vSAN) and network virtualization technologies.",
          "Expertise in cloud platforms such as AWS, Azure, or Google Cloud.",
          "Proficiency in automation tools like Ansible, Terraform, or similar solutions.",
          "Experience with scripting languages like Python, PowerShell, or Bash.",
        ],
      },
      {
        title: "Migration Expertise",
        points: [
          "Proven ability to plan and execute migrations involving on-premises environments and hybrid/cloud solutions.",
          "Experience with workload assessments, data center consolidations, and infrastructure upgrades.",
        ],
      },
      {
        title: "Project Management",
        points: [
          "Demonstrated ability to lead complex migration projects, managing timelines, budgets, and cross-functional teams.",
          "Familiarity with Agile and traditional project management methodologies.",
        ],
      },
      {
        title: "Analytical and Problem-Solving Skills",
        points: [
          "Ability to assess complex IT environments and design effective migration strategies.",
          "Strong troubleshooting skills to address issues during migration processes.",
        ],
      },
      {
        title: "Communication Skills",
        points: [
          "Excellent verbal and written communication skills, capable of conveying technical concepts to non-technical stakeholders.",
        ],
      },
    ],
    Qualifications: [
      "Bachelor’s or Master’s degree in Computer Science, Information Technology, or a related field.",
      "Relevant certifications in cloud platforms or infrastructure technologies (e.g., VCP, NCM, VCP, VCAP, AWS Certified Solutions Architect).",
      "7+ years of experience in IT infrastructure migrations, focusing on modern data centers and cloud platforms.",
    ],
    About: [
      "At AvancerPI Solutions, we pride ourselves on being trusted advisors, delivering IT transformations with precision and innovation. Our focus is on achieving high availability, security, manageability, scalability, and simplicity in every solution we design. We approach every project with a consultative mindset, ensuring our software meets both technical and business requirements.",
    ],
  },
];
let appid;
const generateApplicationId = () => {
  const prefix = "AvancerPiCarriers";
  const timestamp = Date.now().toString(36); // Convert to base-36 string
  const randomNum = Math.random().toString(36).substring(2, 10); // Remove "0." and take the first 8 characters
  appid = `${prefix}-${timestamp}-${randomNum}`;
  return appid;
  // return "APP-" + Math.random().toString(36).substr(2, 9).toUpperCase();
};

export default function JobOpportunityPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [applicationId, setApplicationId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [resume, setResume] = useState(null);

  const [formError, setFormError] = useState("");
  const [forSuccess, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { jobName } = useParams();
  const jobData = jobDetails.find((item) => item?.key === jobName);
  const handleClose = () => {
    setIsDialogOpen(false);
  };


  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  }, []);

  const handleChange = useCallback((e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  }, []);

  const handleFiles = useCallback((files) => {
    const file = files[0];
    if (
      file.type === "application/pdf" ||
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setResume(file);
    } else {
      toast.error("Please upload a PDF or Word document");
    }
  }, []);

  const handleApplyNowClick = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  function CamelCase(input) {
    const parts = input.split('–'); 
    if (parts.length === 0) return "";
    const words = parts[0].trim().split(/\s+/);

    const camelCase = words
        .map((word, index) => {
            const lowerCaseWord = word.toLowerCase();
            return index === 0
                ? lowerCaseWord
                : lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1);
        })
        .join('');
    return camelCase;
  }


  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setFormError("");
      const newApplicationId = generateApplicationId();

      const role = CamelCase(jobData.title)

      const formData = new FormData();

      formData.append("role" , role)
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("appid", newApplicationId);
      if (resume) {
        formData.append("resume", resume);
      }

      try {
        const response = await axios.post("/api/job_application", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          const data = response.data;
          if (data.data) {
            setApplicationId(data.data);
            setIsSubmitted(true);
            // setIsDialogOpen(false);
            setSuccess(data.message);
            toast.success(data.message);
          } else {
            throw new Error("Application ID not received from server");
          }
        } else {
          const errorData = response.data;
          throw new Error(
            errorData.message || "Server responded with an error"
          );
        }
      } catch (error) {
        console.error("Error submitting application:", error);
        setFormError(
          error.message || "Failed to submit the application. Please try again."
        );
      } finally {
        setIsLoading(false);
      }
    },
    [email, phone, resume, name, setIsDialogOpen]
  );

  return (
    <div className="flex justify-center min-h-screen backdrop-blur-md bg-gray-700 relative overflow-hidden">
      <Toaster />
      <div className="absolute inset-0 bg-gray-900 opacity-50" />
      <div className="absolute inset-0">
        <div className="stars" />
        <div className="twinkling" />
      </div>
      <main className="container mx-auto px-4 py-8 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <HashLink to="/ContactUs/career#joinOurTeam">
            <Button
              variant="ghost"
              className="mb-4 text-white flex items-center"
            >
              <ChevronLeft className="mr-2 h-4 w-4" />
              <span>Back to Jobs</span>
            </Button>
          </HashLink>
        </motion.div>

        <AnimatePresence>
          {isSubmitted && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="my-8"
            >
              <div className="backdrop-blur-md bg-gray-900 rounded-xl p-6 text-white">
                <div>
                  <div className="flex items-center text-2xl">
                    <Check className="h-6 w-6 mr-2 text-green-400" />
                    Application Submitted Successfully
                  </div>
                </div>
                <div>
                  <p className="text-lg mb-4">
                    Thank you for applying for the position of {jobData.title}.
                    Your application has been received and is being reviewed by
                    our hiring team.
                  </p>
                  <p className="text-lg font-semibold">
                    Your Application ID:{" "}
                    <span className="text-blue-300">{applicationId}</span>
                  </p>
                  <p className="mt-4 text-sm">
                    Please save this Application ID for future reference. You
                    can use it to check the status of your application later.
                  </p>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className="p-6 rounded-xl backdrop-blur-md bg-gray-900 text-white space-y-8">
          <div>
            {/* Title Section */}
            <div className="flex items-center text-4xl font-bold mb-2">
              <span>{jobData.title}</span>
            </div>
            <div className="text-lg text-gray-300">
              {jobData.department} | {jobData.location} | {jobData.type}
            </div>
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="space-y-10"
          >
            {/* Job Description */}
            <section>
              <h2 className="text-2xl font-semibold border-b border-gray-700 pb-2 mb-4">
                Job Description
              </h2>
              <p className="text-lg leading-relaxed">{jobData.description}</p>
            </section>

            {/* Responsibilities */}
            <section>
              <h2 className="text-2xl font-semibold border-b border-gray-700 pb-2 mb-4">
                Responsibilities
              </h2>
              {jobData.responsibilities.map((responsibility, index) => (
                <div key={index} className="mb-6">
                  <h3 className="text-xl font-medium text-blue-300 mb-2">
                    {responsibility.title}
                  </h3>
                  <ul className="list-disc list-inside space-y-2 pl-5">
                    {responsibility.points.map((point, subIndex) => (
                      <motion.li
                        key={subIndex}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 * subIndex }}
                        className="text-lg"
                      >
                        {point}
                      </motion.li>
                    ))}
                  </ul>
                </div>
              ))}
            </section>

            {/* Requirements */}
            <section>
              <h2 className="text-2xl font-semibold border-b border-gray-700 pb-2 mb-4">
                Requirements
              </h2>
              {jobData.requirements.map((requirement, index) => (
                <div key={index} className="mb-6">
                  <h3 className="text-xl font-medium text-blue-300 mb-2">
                    {requirement.title}
                  </h3>
                  <ul className="list-disc list-inside space-y-2 pl-5">
                    {requirement.points.map((point, subIndex) => (
                      <motion.li
                        key={subIndex}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 * subIndex }}
                        className="text-lg"
                      >
                        {point}
                      </motion.li>
                    ))}
                  </ul>
                </div>
              ))}
            </section>

            {/* Qualifications */}
            <section>
              <h2 className="text-2xl font-semibold border-b border-gray-700 pb-2 mb-4">
                Qualifications
              </h2>
              <ul className="list-disc list-inside space-y-2 pl-5">
                {jobData.Qualifications.map((qualification, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 * index }}
                    className="text-lg"
                  >
                    {qualification}
                  </motion.li>
                ))}
              </ul>
            </section>

            {/* About AvancerPI */}
            <section>
              <h2 className="text-2xl font-semibold border-b border-gray-700 pb-2 mb-4">
                About AvancerPI
              </h2>
              <p className="text-lg">{jobData.About[0]}</p>
            </section>
          </motion.div>

          {/* Apply Now Button */}
          <div>
            <div open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <div asChild>
                <Button
                  size="lg"
                  className="w-full mt-5 sm:w-auto bg-blue-500 hover:bg-blue-600 text-white"
                  onClick={handleApplyNowClick}
                >
                  Apply Now
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="apply-modal-title"
      >
        <Box
          component={motion.div}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.3 }}
          className="m-auto transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg shadow-lg w-full max-w-md"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" component="h3" id="apply-modal-title">
              Apply for {jobData.title}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="start" mb={2}>
            <Typography variant="body2" color="textSecondary">
              Fill out the form below to apply.
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email"
              type="email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              margin="normal"
            />
            <TextField
              fullWidth
              label="Phone"
              type="text"
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              margin="normal"
            />
            <Box
              sx={{
                mt: 2,
                border: "2px dashed",
                borderColor: dragActive ? "primary.main" : "grey.400",
                borderRadius: 1,
                p: 2,
                textAlign: "center",
              }}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={(e) => {
                handleDrag(e);
                handleDrop(e);
              }}
            >
              <input
                id="resume"
                type="file"
                style={{ display: "none" }}
                onChange={handleChange}
                accept=".pdf,.doc,.docx"
                required
              />
              <label htmlFor="resume">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ cursor: "pointer" }}
                >
                  <UploadIcon color="action" fontSize="large" />
                  <Typography variant="body2" color="textSecondary">
                    {resume
                      ? resume.name
                      : "Drag and drop your resume here, or click to select"}
                  </Typography>
                </Box>
              </label>
            </Box>
            {formError ? (
              <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                {formError}
              </Typography>
            ) : forSuccess ? (
              <Typography color="success" variant="body2" sx={{ mt: 2 }}>
                {`${forSuccess}, please save your application ID: ${applicationId} to check the updates.`}
              </Typography>
            ) : null}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 5 }}
              className="hover:bg-blue-700"
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit Application"
              )}
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
