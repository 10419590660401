import Button from "../../components/button";
import Input from "../../components/Input";
import { Card } from "../../components/Card";
import { motion } from "framer-motion";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminLogin = () => {
  let navigate = useNavigate();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(true);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);
    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        "/api/login",
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status !== 200) {
        throw new Error("Login failed");
      }

      const data = response.data;
      if (data.jwtToken) {
        localStorage.setItem("jwtToken", data.jwtToken);
        setSuccess(true);
        toast.success("Login successful! Redirecting...", {
          position: "top-center",
        });
        setTimeout(() => {
          navigate("/AdminDashboard");
        }, 1000);
      } else {
        throw new Error("No token received");
      }
    } catch (err) {
      setError("Authentication failed. Please check your credentials.");
      toast.error("Authentication failed. Please check your credentials.", {
        position: "top-center",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-500/10 via-blue-500/10 to-pink-500/10"
    >
      <Card className="w-full max-w-md p-8 space-y-6 bg-white">
        <div className="text-center space-y-2">
          <h1 className="text-3xl font-bold">Welcome Back</h1>
          <p className="text-muted-foreground">
            Enter your credentials to access the dashboard
          </p>
        </div>
        <div className="space-y-4">
          <div className="space-y-2">
            <Input
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit(e);
                }
              }}
            />
          </div>
          <div className="space-y-2">
            <Input
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit(e);
                }
              }}
            />
          </div>
          <Button className="w-full" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <div className="flex items-center justify-center space-x-2">
                <div className="loader w-4 h-4 border-2 border-t-2 border-gray-200 rounded-full animate-spin"></div>
                <span>Logging In...</span>
              </div>
            ) : (
              "Log In"
            )}
          </Button>
        </div>
      </Card>
      <ToastContainer />
    </motion.div>
  );
};

export default AdminLogin;
